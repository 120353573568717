import styled from 'styled-components'

import { borderedBlock } from '../utils/styles'

const RangeContainer = styled.div`
  ${borderedBlock}
  display: flex;
  padding: 0.5em;
  color: var(--blue);
  font-weight: 500;
  line-height: 100%;

  span {
    display: flex;
    align-items: center;
    margin: 0 1em;
    padding: 0.5em;
  }
`

export default RangeContainer
