import styled from 'styled-components'

import { textOverflow } from '../utils/styles'

export default styled.span`
  ${textOverflow}
  position: relative;
  display: flex;
  padding: 0.125em 0.25em;
  overflow: visible;
  white-space: initial;
  background: var(--soft-green);
  border-radius: 0.25em;
`
