import styled, { css } from 'styled-components'

const left = css`
  transform: rotate(180deg);
`

const ArrowButton = styled.button`
  display: flex;
  padding: 0.5em;
  align-items: center;
  color: inherit;
  background: 0;
  border: 0;
  border-radius: 0.25em;
  outline: 0;
  cursor: pointer;

  &:not(:disabled):hover {
    background: var(--bg-main);
  }

  &:not(:disabled):focus {
    box-shadow: 0 0 0 2px var(--lighter-blue);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    fill: currentColor;
    ${props => (props.left ? left : '')}
  }
`

export default ArrowButton
