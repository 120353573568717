import React from 'react'

import ContentHeader from '../shared/ContentHeader'
import ReturnButton from '../shared/ReturnButton'
import MonthSelect from '../shared/MonthSelect'

const Header = ({
  toCurrentMonth,
  selectedMonth,
  setSelectedMonth,
  loading,
}) => {
  return (
    <ContentHeader>
      <ReturnButton handler={toCurrentMonth} />
      <MonthSelect
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        loading={loading}
      />
    </ContentHeader>
  )
}

export default Header
