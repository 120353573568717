import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -91 460 460"
    width="24"
    height="24"
  >
    <path d="M354.156 29H73.2c-.992 20-4.52 32.672-11.187 41.375C55.102 79.395 45 83.985 29 85.102v79.304c16 1.098 26.133 5.871 32.992 14.754C68.68 187.82 72.211 200 73.2 221h280.422C356.133 185 373 166.754 407 164.406v-79.3c-16-1.114-28.223-5.696-36.8-14.094C360.741 61.738 355.46 48 354.155 29zM215.758 208.625c-46.32 0-83.871-37.55-83.871-83.871 0-46.32 37.55-83.871 83.87-83.871 46.321 0 83.872 37.55 83.872 83.87-.05 46.302-37.574 83.821-83.875 83.872zm0 0" />
    <path d="M287.625 124.754c0 39.695-32.176 71.871-71.871 71.871-39.691 0-71.871-32.176-71.871-71.871 0-39.692 32.18-71.871 71.87-71.871 39.696 0 71.872 32.18 71.872 71.87zm0 0" />
    <path d="M444 255.813a6.38 6.38 0 01-6.254 6.187H28v16h432V28h-16zm0 0" />
    <path d="M432 0H0v250h432zm-13 170.156a6.08 6.08 0 01-5.914 6.082c-32.992.535-46.832 15.297-47.742 50.746a6.148 6.148 0 01-6 6.016H67.414a6.206 6.206 0 01-6.066-6.016c-1.078-42.5-11.028-50.386-38.301-50.828a6.121 6.121 0 01-6.043-6v-90.8a6.107 6.107 0 016.043-5.981c15.262-.246 24.207-3.324 29.578-10.336 5.555-7.242 8.266-19.637 8.797-40.266a5.932 5.932 0 016-5.77H359.89a5.94 5.94 0 016 5.77c.48 18.786 4.64 31.684 12.71 39.594 7.387 7.238 18.352 10.73 34.489 10.988a6.012 6.012 0 015.914 6zm0 0" />
  </svg>
)
