import styled from 'styled-components'

import { borderedBlock } from '../utils/styles'

export default styled.div`
  ${borderedBlock}
  display: flex;
  padding: calc(0.75em - 1px) calc(1em - 1px);

  svg {
    align-self: center;
    fill: var(--blue);
    margin-right: 1.5em;
  }

  ul {
    list-style: none;
    display: flex;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      margin: 0;
    }

    li:not(:last-child) {
      margin-right: 1em;
    }
  }
`

export const Label = styled.span`
  margin-right: 1em;
  color: var(--blue);
  font-weight: 500;
`
