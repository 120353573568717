import React from 'react'
import styled from 'styled-components'

import Button from '../../shared/StyledButton'
import Tooltip from '../../shared/Tooltip'
import TurnBackIcon from '../../shared/icons/TurnBack'

const StyledButton = styled(Button)`
  width: 32px;
`

const CancelButton = ({ handleClick }) => (
  <StyledButton type="button" onClick={handleClick}>
    <TurnBackIcon />
    <Tooltip>Отмена</Tooltip>
  </StyledButton>
)

export default CancelButton
