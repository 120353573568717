import React from 'react'
import DatePicker from 'react-date-picker'
import { startOfToday } from 'date-fns'

import ContentHeader from '../shared/ContentHeader'
import ReturnButton from '../shared/ReturnButton'

const RidesHeader = ({ toThisDay, selectedDay, setSelectedDay }) => {
  const handleDate = e => setSelectedDay(e)
  return (
    <ContentHeader>
      <ReturnButton handler={toThisDay} />
      <DatePicker
        locale="ru-RU"
        showLeadingZeros
        value={selectedDay}
        onChange={handleDate}
        maxDate={startOfToday()}
        clearIcon={null}
        calendarIcon={null}
        dayPlaceholder={'дд'}
        monthPlaceholder={'мм'}
        yearPlaceholder={'гггг'}
        required
      />
    </ContentHeader>
  )
}

export default RidesHeader
