import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"
    width="16"
    height="16"
  >
    <g clipPath="url(#clip0)">
      <path d="M9.858 17.594a2.125 2.125 0 01-1.508.625 2.125 2.125 0 01-1.508-.625L1.51 12.263l-.002-.002L0 10.753l1.507-1.507.003-.003 4.977-4.977a2.132 2.132 0 113.016 3.017L8.167 8.619H24.35c6.666 0 12.088 5.424 12.088 12.089 0 6.666-5.423 12.089-12.088 12.089H3.017a2.133 2.133 0 110-4.267H24.35c4.313 0 7.822-3.51 7.822-7.822s-3.509-7.821-7.822-7.821H8.166l1.691 1.691a2.13 2.13 0 01.001 3.016z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h36.438v36.438H0z"
          transform="matrix(-1 0 0 1 36.438 0)"
        />
      </clipPath>
    </defs>
  </svg>
)
