import styled from 'styled-components'

const ContentHeader = styled.header`
  display: flex;
  padding: 1em;
  background: var(--main-bg);

  & > *:not(:last-child) {
    margin-right: 1em;
  }
`

export default ContentHeader
