import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import GreenBlock from '../shared/GreenBlock'
import SteeringWheelIcon from '../shared/icons/SteeringWheel'
import HeadsetIcon from '../shared/icons/Headset'
import HomeIcon from '../shared/icons/Home'
import InfoIcon from '../shared/icons/Info'
import { routeLink, cities } from '../utils/constants'
import TableRow from './Table/TableRow'
import RouteLink from './Table/RouteLink'
import Comment from './Table/Comment'
import RedactorInfo from './Table/RedactorInfo'
import StatusButton from './Table/StatusButton'
import { UserContext } from '../shared/Layout'

const StyledGreenBlock = styled(GreenBlock)`
  border: 1px solid var(--green);
`

const CustomerName = styled.span`
  display: inline-flex;
  min-width: 100%;
  text-align: left;
`

const StatusText = styled.span`
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: 0.25em;
  border: 1px solid
    ${props => (props.isOpen ? 'var(--red)' : 'var(--light-green)')};
  background: ${props =>
    props.isOpen ? 'var(--light-red)' : 'var(--soft-green)'};
  border-radius: 0.25em;
`

const FeedbacksTable = ({
  openedOnly,
  feedbacks,
  setFeedbacks,
  allOpened,
  setAllOpened,
  api,
}) => {
  const user = useContext(UserContext)
  const [loading, setLoading] = useState(-1)
  const { fullName } = user
  const renderFeedbacks = openedOnly ? allOpened : feedbacks

  const applyFeedbackChanges = (newRow, index) => {
    if (newRow.isOpen) {
      setFeedbacks(prevFeedbacks => {
        const newFeedbacks = prevFeedbacks.filter((_, i) => i !== index)
        newFeedbacks.splice(index, 0, newRow)
        return newFeedbacks
      })
      setAllOpened(prevOpened => {
        const newOpened = prevOpened.filter((_, i) => i !== index)
        newOpened.splice(index, 0, newRow)
        return newOpened
      })
    } else {
      setFeedbacks(prevFeedbacks => {
        const newFeedbacks = prevFeedbacks.filter(_ => _.id !== newRow.id)
        newFeedbacks.splice(0, 0, newRow)
        return newFeedbacks
      })
      setAllOpened(prevOpened => {
        const newOpened = prevOpened.filter((_, i) => i !== index)
        return newOpened
      })
    }
  }

  const handleToggleFeedback = index => async () => {
    setLoading(index)

    const newRow = openedOnly
      ? { ...allOpened[index] }
      : { ...feedbacks[index] }
    newRow.isOpen = !newRow.isOpen
    newRow.staffName = fullName
    newRow.updatedAt = new Date()

    try {
      await api.updateFeedback(newRow)
      applyFeedbackChanges(newRow, index)
    } catch (e) {
      console.log(e)
    }
    setLoading(-1)
  }

  const handleCommentChange = index => event => {
    const { value } = event.target
    const newRow = openedOnly
      ? { ...allOpened[index] }
      : { ...feedbacks[index] }
    newRow.staffComment = value
    openedOnly
      ? setAllOpened(prevOpened => {
          const newOpened = prevOpened.filter((_, i) => i !== index)
          newOpened.splice(index, 0, newRow)
          return newOpened
        })
      : setFeedbacks(prevFeedbacks => {
          const newFeedbacks = prevFeedbacks.filter((_, i) => i !== index)
          newFeedbacks.splice(index, 0, newRow)
          return newFeedbacks
        })
  }

  return (
    <table>
      <thead>
        <tr>
          <th>ФИО</th>
          <th>Номер</th>
          <th title="Оценка водителя">
            <SteeringWheelIcon />
          </th>
          <th title="Оценка оператора">
            <HeadsetIcon />
          </th>
          <th title="Оценка компании">
            <HomeIcon />
          </th>
          <th>Текст отзыва</th>
          <th title="Информация о маршруте">
            <InfoIcon />
          </th>
          <th>Комментарий</th>
          <th>Статус</th>
        </tr>
      </thead>
      <tbody>
        {renderFeedbacks === undefined || renderFeedbacks.length < 1 ? (
          <tr>
            <td className="stretch">Данные за выбранный период отсутствуют</td>
          </tr>
        ) : (
          renderFeedbacks.map(
            (
              {
                id,
                driver_rating,
                operator_rating,
                company_rating,
                fio,
                phone,
                comment,
                route_id,
                routeDateTime,
                from,
                to,
                driverName,
                isOpen,
                updatedAt,
                staffComment,
                staffName,
              },
              index
            ) => (
              <TableRow
                key={id}
                score={Math.min(driver_rating, operator_rating, company_rating)}
              >
                <td>
                  <CustomerName>{fio}</CustomerName>
                </td>
                <td>{phone}</td>
                <td>
                  <strong>{driver_rating}</strong>
                </td>
                <td>
                  <strong>{operator_rating}</strong>
                </td>
                <td>
                  <strong>{company_rating}</strong>
                </td>
                <td>
                  <small>{comment}</small>
                </td>
                <td>
                  <RouteLink
                    href={`${routeLink}${route_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Посмотреть маршрут"
                  >
                    <StyledGreenBlock>{`${cities[from]} > ${cities[to]}`}</StyledGreenBlock>
                    <time>
                      {routeDateTime &&
                        format(new Date(routeDateTime), "dd.MM.yy', 'HH:mm")}
                    </time>
                    <span>Водитель: {driverName}</span>
                  </RouteLink>
                </td>
                <td>
                  <Comment
                    value={staffComment || ''}
                    disabled={!isOpen}
                    onChange={handleCommentChange(index)}
                    placeholder="Введите комментарий"
                    rows="3"
                  />
                  <RedactorInfo>
                    {staffName}
                    <br />
                    {updatedAt &&
                      format(new Date(updatedAt), "dd.MM.yy', 'HH:mm")}
                  </RedactorInfo>
                </td>
                <td>
                  <StatusText isOpen={isOpen}>
                    {isOpen ? 'Открыто' : 'Закрыто'}
                  </StatusText>
                  <StatusButton
                    disabled={loading === index}
                    onClick={handleToggleFeedback(index)}
                  >
                    <span>
                      {loading === index
                        ? '...'
                        : isOpen
                        ? 'Закрыть'
                        : 'Открыть'}
                    </span>
                  </StatusButton>
                </td>
              </TableRow>
            )
          )
        )}
      </tbody>
    </table>
  )
}

export default FeedbacksTable
