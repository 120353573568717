import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="16"
    height="16"
  >
    <path d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm42.667 416A10.66 10.66 0 01288 426.667h-64A10.66 10.66 0 01213.333 416V256h-10.667a10.66 10.66 0 01-10.667-10.667v-42.667a10.66 10.66 0 0110.667-10.667H288a10.66 10.66 0 0110.667 10.667V416zM256 170.667c-23.531 0-42.667-19.135-42.667-42.667S232.469 85.333 256 85.333s42.667 19.135 42.667 42.667-19.136 42.667-42.667 42.667z" />
  </svg>
)
