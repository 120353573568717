import styled from 'styled-components'

const StatusButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: 0.5em;
  color: var(--white);
  background: var(--blue);
  border: 1px solid var(--dark-blue);
  border-radius: 0.25em;
  cursor: pointer;

  &:hover:not(:active) {
    background-color: var(--light-blue);
  }
`

export default StatusButton
