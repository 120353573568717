import React from 'react'

import ContentHeader from '../shared/ContentHeader'
import Schema from './Header/Schema'
import WeekSelect from './Header/WeekSelect'
import ReturnButton from '../shared/ReturnButton'

const Header = ({
  toThisWeek,
  setSelectedWeekStart,
  selectedWeekStart,
  carSchemas,
  hiddenSchemas,
  setHiddenSchemas,
}) => {
  return (
    <ContentHeader>
      <ReturnButton handler={toThisWeek} />
      <WeekSelect
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
      />
      <Schema
        carSchemas={carSchemas}
        hiddenSchemas={hiddenSchemas}
        setHiddenSchemas={setHiddenSchemas}
      />
    </ContentHeader>
  )
}

export default Header
