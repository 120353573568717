import React from 'react'
import styled from 'styled-components'

import ReturnIcon from './icons/ReturnIcon'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: calc(0.75em - 1px);
  background: var(--blue);
  border: 1px solid var(--dark-blue);
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  transition: background 0.2s;

  &:hover:not(:active) {
    background: var(--light-blue);
  }

  &:focus {
    box-shadow: 0 0 0 2px orange;
  }

  &:disabled {
    background: var(--light-gray);
    border-color: var(--gray);
    pointer-events: none;

    && svg {
      fill: var(--gray);
    }
  }

  && > svg {
    fill: var(--white);
  }
`

const ReturnButton = ({ handler, isDisabled }) => {
  return (
    <Button
      ariaLabel="К текущей неделе"
      onClick={handler}
      disabled={isDisabled}
    >
      <ReturnIcon />
    </Button>
  )
}

export default ReturnButton
