import styled from 'styled-components'

export default styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.75em;
  font-size: 1.2em;

  && {
    margin: 0 4em;
  }
`
