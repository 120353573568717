import React from 'react'

import Button from './StyledButton'
import Tooltip from './Tooltip'
import ReturnIcon from './icons/ReturnIcon'

const EditButton = ({ handleClick }) => {
  return (
    <Button type="button" onClick={handleClick}>
      <ReturnIcon />
      <Tooltip>Обновить</Tooltip>
    </Button>
  )
}

export default EditButton
