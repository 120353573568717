import styled from 'styled-components'

import { textOverflow } from '../utils/styles'

export const NoWrappingText = styled.span`
  ${textOverflow}
`

export default styled.small`
  position: absolute;
  top: 80%;
  left: 50%;
  z-index: 1;
  display: block;
  max-width: 400%;
  padding: 0.25em 0.5em;
  color: var(--white);
  font-weight: 400;
  text-align: center;
  background: var(--transparent-black);
  border-radius: 0.25em;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
`
