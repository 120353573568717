import React from 'react'

const ReturnIcon = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.649 2.35A7.965 7.965 0 007.995 0 7.992 7.992 0 000 8c0 4.42 3.572 8 7.995 8 3.732 0 6.844-2.55 7.735-6h-2.082c-.82 2.33-3.041 4-5.653 4a6.007 6.007 0 01-6.004-6c0-3.31 2.692-6 6.004-6 1.661 0 3.142.69 4.223 1.78L8.996 7H16V0l-2.351 2.35z" />
    </svg>
  )
}

export default ReturnIcon
