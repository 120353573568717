import React, { useEffect, useState } from 'react'
import {
  startOfDay,
  endOfDay,
  startOfToday,
  startOfMonth,
  endOfMonth,
} from 'date-fns'
import styled, { css } from 'styled-components'

import api from '../../services/api'
import SEO from '../shared/seo'
import TableContainer from '../shared/TableContainer'
import Header from './Header'
import FeedbacksTable from './FeedbacksTable'
import RatingTable from './RatingTable'
import Loader from '../shared/Loader'

const FeedbacksGrid = css`
  grid-template-columns: auto auto repeat(3, 50px) 2fr auto 2fr 120px;
`

const RatingGrid = css`
  grid-template-columns: repeat(7, auto);
`

const StyledContainer = styled(TableContainer)`
  ${props => (props.currentTab === 0 ? FeedbacksGrid : RatingGrid)}

  td {
    padding: 1em;
  }
`

const Rating = () => {
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [selectedDay, setSelectedDay] = useState(startOfToday())
  const [selectedMonth, setSelectedMonth] = useState(startOfToday())
  const [feedbacks, setFeedbacks] = useState([])
  const [allOpened, setAllOpened] = useState([])
  const [ratings, setRatings] = useState([])
  const [openedOnly, setOpenedOnly] = useState(true)
  const toToday = () => {
    setSelectedDay(startOfDay(new Date(), { weekStartsOn: 1 }))
  }
  const toCurrentMonth = () => {
    setSelectedMonth(startOfDay(new Date(), { weekStartsOn: 1 }))
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      api
        .getFeedback(startOfDay(selectedDay), endOfDay(selectedDay))
        .then(({ data }) => {
          setAllOpened(data.allOpened)
          setFeedbacks(data.forDay)
        }),
      api
        .getDriverRating(startOfMonth(selectedMonth), endOfMonth(selectedMonth))
        .then(({ data }) => {
          const ratings = data.map(
            ({
              driver: { carSchema, name, surname, patronymic },
              ...rest
            }) => ({
              carSchema,
              driverName: `${surname} ${name} ${patronymic}`,
              ...rest,
            })
          )
          setRatings(ratings)
        }),
    ]).then(() => setLoading(false))
  }, [selectedDay, selectedMonth])

  return (
    <StyledContainer currentTab={currentTab}>
      <SEO title="Оценка качества обслуживания" />
      <Header
        toToday={toToday}
        toCurrentMonth={toCurrentMonth}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        openedOnly={openedOnly}
        setOpenedOnly={setOpenedOnly}
        loading={loading}
      />
      {loading || feedbacks === undefined ? (
        <Loader />
      ) : currentTab === 0 ? (
        <FeedbacksTable
          allOpened={allOpened}
          feedbacks={feedbacks}
          setFeedbacks={setFeedbacks}
          setAllOpened={setAllOpened}
          api={api}
          openedOnly={openedOnly}
        />
      ) : (
        <RatingTable ratings={ratings} />
      )}
    </StyledContainer>
  )
}

export default Rating
