import React from 'react'
import styled from 'styled-components'

import { textOverflow } from '../../../utils/styles'

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: 1.2em;
  padding: 1em;
  max-width: 100%;
  line-height: 100%;
`

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-weight: 500;
  background: var(--orange);
  border-radius: 50%;
  user-select: none;
`

const MainInfo = styled.div`
  display: grid;
  align-items: center;
  max-width: 100%;
`

const Name = styled.span`
  display: block;
  font-weight: 500;
  ${textOverflow}
`

const Title = styled.small`
  font-size: 0.75em;
  ${textOverflow}
`

const UserInfo = ({ initials, fullName, jobTitle }) => {
  return (
    <Container>
      <Badge ariaHidden={true}>{initials}</Badge>
      <MainInfo>
        <Name>{fullName}</Name>
        <Title>{jobTitle}</Title>
      </MainInfo>
    </Container>
  )
}

export default UserInfo
