import styled, { css } from 'styled-components'

import { centeredFlex } from '../utils/styles'

export default styled.div`
  position: relative;
  display: grid;
  padding: 0 1em 4em;

  header,
  thead {
    background: var(--bg-main);
  }

  header,
  th,
  tfoot td {
    position: sticky;
    z-index: 1;
  }

  header {
    top: 0;
    z-index: 3;
    grid-column-start: 1;
    grid-column-end: -1;
    display: flex;
    align-items: center;
    height: 70px;
    padding: 1em 0.25em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background: var(--bg-main);
    }

    &::before {
      left: -1px;
    }

    &::after {
      right: -1px;
    }
  }

  table,
  thead,
  tbody,
  tfoot,
  tr {
    display: contents;
  }

  tbody {
    td:only-of-type {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  table {
    font: inherit;
  }

  tr {
    &:nth-child(even) > td {
      background: var(--soft-gray);
    }
  }

  th,
  td {
    ${centeredFlex}
    flex-direction: column;
    margin: -0.5px;
    line-height: 100%;
    padding: 0.75em 0.5em;
    background: var(--white);
    border: 1px solid var(--light-gray);

    &:hover > small {
      opacity: 1;
    }

    & > *:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  th {
    top: 70px;
    z-index: 2;
    color: var(--dark-gray);
    font-weight: 500;
    background: var(--lightest-gray);
  }

  td {
    position: relative;
  }

  svg {
    fill: currentColor;
  }
`

export const FixedFooter = css`
  tfoot {
    td {
      top: 119px;
      font-weight: 500;
      background: var(--ghost-green);
    }

    td:first-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`
