import styled from 'styled-components'

const Comment = styled.textarea`
  appearance: none;
  display: block;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 120%;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--gray);
  border-radius: 0.5em;
  resize: none;

  &::placeholder {
    color: var(--gray);
  }

  &:disabled {
    cursor: not-allowed;
    background: rgba(255, 255, 255, 0.3);
    border-color: var(--light-gray);
  }
`

export default Comment
