import React from 'react'

const ArrowIcon = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.12 1.88L9.227 8 3.12 14.12 5 16l8-8-8-8-1.88 1.88z" />
    </svg>
  )
}

export default ArrowIcon
