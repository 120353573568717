import React from 'react'

const LogoutIcon = () => {
  return (
    <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.171 11.191l-1.234 1.253L.564 8l4.375-4.444L6.17 4.809 3.914 7.11h8.461V8.89H3.914L6.17 11.19zM14.562 0H2.313c-.97 0-1.75.8-1.75 1.778v3.555l1.75-1.777V1.778h12.25v12.444H2.313v-1.778l-1.75-1.777v3.555c0 .978.78 1.778 1.75 1.778h12.25c.963 0 1.75-.8 1.75-1.778V1.778C16.313.8 15.525 0 14.563 0z" />
    </svg>
  )
}

export default LogoutIcon
