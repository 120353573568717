import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="24"
    height="24"
  >
    <path d="M272.543 433.51l-188.18-87.13 68.57-34.8c-6.894-6.894-15.008-16.06-20.82-23.08l-88.91 45.12c-11.168 5.671-10.843 21.758.49 26.99l257.92 119.41c-10.276-14.281-20.456-30.342-29.07-46.51zM183.993 0c-56.794 0-103 46.206-103 103 0 93.142 88.621 182.834 92.394 186.606 5.857 5.858 15.355 5.859 21.213 0 3.772-3.772 92.394-93.464 92.394-186.606-.001-56.794-46.207-103-103.001-103zm-.493 146.5c-23.986 0-43.5-19.514-43.5-43.5s19.514-43.5 43.5-43.5S227 79.014 227 103s-19.514 43.5-43.5 43.5z" />
    <path d="M183.5 89.5c-7.444 0-13.5 6.056-13.5 13.5s6.056 13.5 13.5 13.5S197 110.444 197 103s-6.056-13.5-13.5-13.5z" />
    <g>
      <path d="M373.993 218c-56.794 0-103 46.206-103 103 0 93.142 88.621 182.834 92.394 186.606 5.857 5.858 15.355 5.859 21.213 0 3.772-3.772 92.394-93.464 92.394-186.606-.001-56.794-46.207-103-103.001-103zm-.493 147c-23.986 0-43.5-19.514-43.5-43.5s19.514-43.5 43.5-43.5 43.5 19.514 43.5 43.5-19.514 43.5-43.5 43.5z" />
      <path d="M373.5 308c-7.444 0-13.5 6.056-13.5 13.5s6.056 13.5 13.5 13.5 13.5-6.056 13.5-13.5-6.056-13.5-13.5-13.5z" />
    </g>
  </svg>
)
