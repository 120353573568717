import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192 192"
    width="16"
    height="16"
  >
    <path d="M96 0a96 96 0 1096 96A96.108 96.108 0 0096 0zM28.8 118.869a281.5 281.5 0 0143.271-6.081s4.65 18.353 6.829 52.112a71.264 71.264 0 01-50.1-46.031zM96 112a8 8 0 118-8 8 8 0 01-8 8zm17.1 52.906c2.176-33.778 6.832-52.118 6.832-52.118a281.412 281.412 0 0143.271 6.084 71.266 71.266 0 01-50.103 46.034zM96 80a209.053 209.053 0 00-70.916 12.69 70.988 70.988 0 01141.845.531A201.7 201.7 0 0096 80z" />
  </svg>
)
