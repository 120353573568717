import styled from 'styled-components'

export default styled.input`
  appearance: none;
  display: flex;
  max-width: 3em;
  padding: 0;
  padding-top: 1px;
  color: inherit;
  line-height: inherit;
  text-align: center;
  background: 0;
  border: 0;
  border-bottom: 1px dashed transparent;

  &:not(:disabled) {
    background: var(--light-yellow);
    border-bottom-color: var(--dark-yellow);
  }
`
