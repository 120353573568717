import React from 'react'
import styled from 'styled-components'

import Button from '../../shared/StyledButton'
import Tooltip from '../../shared/Tooltip'
import CheckIcon from '../../shared/icons/CheckSign'

const StyledButton = styled(Button)`
  background: var(--green);
  border-color: var(--dark-green);

  &:hover {
    background: var(--light-green);
  }
`

const SubmitButton = ({ handleClick }) => (
  <StyledButton type="button" onClick={handleClick}>
    <CheckIcon />
    <Tooltip>Сохранить</Tooltip>
  </StyledButton>
)

export default SubmitButton
