import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="24"
    height="24"
  >
    <path d="M45.911 292.219l-12.803 3.305a23.49 23.49 0 00-17.429 19.767L.192 436.501c-1.644 12.869 7.455 24.634 20.324 26.278 12.866 1.642 24.633-7.452 26.278-20.324l2.613-20.45v-62.88c0-13.851 9.683-25.474 22.633-28.478z" />
    <path d="M243.71 159.627c-11.854-3.34-24.174 3.561-27.515 15.416l-30.662 108.794 3.139-96.584c2.694-16.027-8.281-32.216-26.588-33.948l-29.35-4.834c-15.239-1.441-29.142 9.111-31.673 24.208l-16.387 53.588 14.957-.904 22.443-21.576-21.479 32.334-69.736 4.216c-10.792.653-19.011 9.929-18.359 20.72.651 10.758 9.894 19.012 20.72 18.359l79.468-4.804a19.576 19.576 0 0015.124-8.708l26.873-40.454-17.88 46.427c-5.831 15.141-21.982 13.095-23.466 13.511L58.55 288.7l27.662 40.684h2.884v-1.809c0-12.997 10.573-23.571 23.57-23.571h31.354c6.499 0 12.391 2.644 16.659 6.912 7.971 7.971 6.803 17.886 6.911 18.468h10.45c16.123 0 29.24 13.117 29.24 29.24v1.92a22.232 22.232 0 005.71-9.714l46.133-163.686c3.343-11.857-3.558-24.175-15.413-27.517zM388.559 145.052c23.802-1.689 41.728-22.354 40.038-46.156-1.689-23.802-22.354-41.728-46.156-40.038-23.802 1.689-41.728 22.354-40.038 46.156 1.689 23.802 22.353 41.728 46.156 40.038zM163.158 135.334c23.802-1.689 41.728-22.354 40.038-46.156-1.689-23.802-22.354-41.728-46.156-40.038-23.802 1.689-41.728 22.354-40.038 46.156 1.689 23.802 22.354 41.727 46.156 40.038z" />
    <path d="M495.742 160.13c-11.854-3.341-24.173 3.561-27.515 15.416l-20.665 73.322v-56.492c0-18.828-15.263-34.092-34.092-34.092h-32.259c-18.829 0-34.092 15.263-34.092 34.092v5.828l16.786 15.893 10.201-9.807-14 21.076-39.431-37.334-13.174-45.108a7.988 7.988 0 00-15.334 4.477l10.437 35.737a19.477 19.477 0 00-9.682 5.575c-7.433 7.85-7.095 20.24.756 27.673l56.402 53.403c8.845 8.375 23.065 6.702 29.764-3.383l26.873-40.454-17.88 46.427c-6.598 17.134-28.424 21.95-41.654 9.542l-2.001-1.898-60.042 15.501a23.489 23.489 0 00-17.429 19.767l-15.488 121.21c-1.795 14.046 9.149 26.47 23.33 26.47 11.646 0 21.756-8.657 23.271-20.515l9.564-74.848v-9.569c0-16.123 13.117-29.24 29.24-29.24h10.45v-1.809c0-13.098 10.692-23.57 23.57-23.57h31.354c12.991 0 23.571 10.569 23.571 23.571v1.809h10.45c13.507 0 24.899 9.208 28.242 21.674l45.891-162.828c3.343-11.856-3.559-24.175-15.414-27.516z" />
    <path d="M437.025 341.139h-22.092v-13.406c0-6.577-5.351-11.928-11.928-11.928H371.65c-6.577 0-11.928 5.351-11.928 11.928v13.406h-22.093c-9.439 0-17.092 8.203-17.092 17.643v77.213c0 9.414 7.627 17.092 17.092 17.092h2.175v3.764a6.12 6.12 0 006.12 6.12h15.374a6.12 6.12 0 006.12-6.12v-3.764h39.817v3.764a6.12 6.12 0 006.12 6.12h15.374a6.12 6.12 0 006.12-6.12v-3.764h2.174c9.439 0 17.092-7.652 17.092-17.092v-77.213c.002-9.439-7.65-17.643-17.09-17.643zm-77.287 0c.009-.082.021-.162.035 0zm43.046 0H371.87v-13.186h30.914zm12.133 0h-.035c.014-.162.026-.081.035 0zM178.042 341.139H155.95v-13.406c0-6.577-5.351-11.928-11.928-11.928h-31.354c-6.577 0-11.928 5.351-11.928 11.928v13.406H78.646c-9.44 0-17.092 8.204-17.092 17.643v77.213c0 9.502 7.713 17.092 17.092 17.092h2.174v3.763a6.12 6.12 0 006.12 6.121h15.374a6.12 6.12 0 006.12-6.121v-3.763h39.817v3.763a6.12 6.12 0 006.12 6.121h15.374a6.12 6.12 0 006.12-6.121v-3.763h2.175c9.496 0 17.092-7.709 17.092-17.092v-77.213c.002-9.439-7.651-17.643-17.09-17.643zm-77.288 0c.009-.082.022-.162.035 0zm43.047 0h-30.914v-13.186h30.914zm12.098 0c.013-.162.026-.082.035 0z" />
  </svg>
)
