import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
  >
    <path d="M7.992 0C3.576 0 0 3.584 0 8s3.576 8 7.992 8C12.416 16 16 12.416 16 8s-3.584-8-8.008-8zm3.392 12.8L8 10.76 4.616 12.8l.896-3.848-2.984-2.584 3.936-.336L8 2.4l1.536 3.624 3.936.336-2.984 2.584.896 3.856z" />
  </svg>
)
