import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { startOfDay, startOfToday, startOfMonth, endOfMonth } from 'date-fns'

import api from '../../services/api'
import SEO from '../shared/seo'
import TableContainer, { FixedFooter } from '../shared/TableContainer'
import Header from './Header'
import Table from './Table'

const StyledContainer = styled(TableContainer)`
  ${FixedFooter}
  grid-template-columns: repeat(3, auto);
  max-width: 640px;

  tfoot {
    td {
      top: 41px;
    }

    td:first-child {
      grid-column-end: 3;
    }
  }
`

const Main = () => {
  const [loading, setLoading] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(startOfToday())
  const [logs, setLogs] = useState([])

  const toCurrentMonth = () => {
    setSelectedMonth(startOfDay(new Date(), { weekStartsOn: 1 }))
  }

  useEffect(() => {
    setLoading(true)
    api
      .getLogs(startOfMonth(selectedMonth), endOfMonth(selectedMonth))
      .then(({ data }) => {
        setLogs(data)
      })
      .then(() => setLoading(false))
  }, [selectedMonth])

  return (
    <StyledContainer>
      <SEO title="Главная" />
      <Header
        toCurrentMonth={toCurrentMonth}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        loading={loading}
      />
      <Table logs={logs} loading={loading} />
    </StyledContainer>
  )
}

export default Main
