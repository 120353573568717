export const routeLink = 'https://crmbus.ru/#/company/6/route/'

export const roles = {
  1: 'Супер админ',
  2: 'Админ компании',
  3: 'Диспетчер',
  4: 'Агент',
}

export const cities = {
  '1': 'М',
  '10': 'В',
  '21': 'Армв',
  '23': 'А',
  '38': 'Влкв',
  '40': 'Влж',
  '44': 'Гндж',
  '52': 'Ес',
  '53': 'Жквс',
  '62': 'Клг',
  '67': 'Кслв',
  '72': 'Крсд',
  '75': 'Крск',
  '90': 'Нлч',
  '98': 'Нврс',
  '103': 'Обн',
  '116': 'Птг',
  '119': 'Р',
  '133': 'Сч',
  '134': 'Ств',
  '139': 'Тгн',
  '143': 'Тлт',
  '155': 'Хмк',
  '160': 'Грзн',
  '164': 'Шхт',
  '166': 'Э',
  '354': 'Анп',
  '355': 'Лгн',
  '669': 'Жлзвд',
  '818': 'Ялта',
  '1261': 'Мхлв',
  '1449': 'Бднв',
  '1582': 'Прлт',
  '1797': 'Мнв',
  '3060': 'Ипт',
  '3978': 'Цмлн',
  '6310': 'Трц',
  '8748': 'Змв',
  '3462': 'Афп',
  '3700': 'Влгдн',
  '6356': 'ЦгА',
  '7188': 'Сдк',
  '8963': 'Ктч',
  '12895': 'ИкБ',
  '14209': 'Звтн',
  '17290': 'Втзв',
  '17472': 'МлДб',
  '19893': 'ПтрВл',
  '20320': 'АрхОсп',
  '1004359': 'Акс',
  '1014402': 'Дальн',
  '1014887': 'Солод',
  '1030609': 'Ашг',
  '1031804': 'Грдв',
  '1031866': 'ШнМр',
  '1031921': 'Пртн',
  '1042266': 'Ольг',
  '1042272': 'Плх',
  '1053557': 'Внкв',
  '1054308': 'Дмдв',
  '1079156': 'Дмтрд',
}

export const citiesName = {
  '1': 'Москва',
  '10': 'Волгоград',
  '21': 'Армавир',
  '23': 'Астрахань',
  '38': 'Владикавказ',
  '40': 'Волжский',
  '44': 'Геленджик',
  '52': 'Ессентуки',
  '53': 'Жуковский',
  '62': 'Калуга',
  '67': 'Кисловодск',
  '72': 'Краснодар',
  '75': 'Курск',
  '90': 'Нальчик',
  '98': 'Новороссийск',
  '103': 'Обнинск',
  '116': 'Пятигорск',
  '119': 'Ростов-на-Дону',
  '133': 'Сочи',
  '134': 'Ставрополь',
  '139': 'Таганрог',
  '143': 'Тольятти',
  '155': 'Химки',
  '160': 'Грозный',
  '164': 'Шахты',
  '166': 'Элиста',
  '354': 'Анапа',
  '355': 'Лагань',
  '669': 'Железноводск',
  '818': 'Ялта',
  '1261': 'Михайловск',
  '1449': 'Буденновск',
  '1582': 'Пролетарск',
  '1797': 'Минеральные Воды',
  '3060': 'Ипатово',
  '3462': 'Афипский',
  '3700': 'Волгодонск',
  '3978': 'Цимлянск',
  '6310': 'Троицкое',
  '6356': 'Цаган-Аман',
  '7188': 'Судак',
  '8748': 'Зимовники',
  '8963': 'Кетченеры',
  '12895': 'Ики-Бурул',
  '14209': 'Заветное',
  '17290': 'Витязево',
  '17472': 'Малые Дербеты',
  '19893': 'Петров Вал',
  '20320': 'Архипо-Осиповка',
  '1004359': 'Аксарайский',
  '1014402': 'Дальний',
  '1031866': 'Шин-Мер',
  '1031921': 'Приютное',
  '1042266': 'Ольгинка',
  '1042272': 'Пляхо',
  '1079156': 'Дмитриадовка',
  '1030609': 'Аушигер',
  '1031804': 'Городовиковск',
  '1053557': 'Внуково',
  '1054308': 'Домодедово',
  '1014887': 'Солодушино',
}
