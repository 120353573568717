import React, { useState } from 'react'
import { getHours, getMinutes } from 'date-fns'

import EditableCell from '../shared/EditableCell'
import GreenBlock from '../shared/GreenBlock'
import EditButton from './RidesTable/EditButton'
import SubmitButton from './RidesTable/SubmitButton'
import CancelButton from './RidesTable/CancelButton'
import RefreshButton from '../shared/RefreshButton'
import TimeIcon from '../shared/icons/Time'
import RouteIcon from '../shared/icons/Route'
import CreditIcon from '../shared/icons/Credit'
import CashIcon from '../shared/icons/Cash'
import TicketIcon from '../shared/icons/Ticket'
import TotalCashIcon from '../shared/icons/TotalCash'
import PaymentIcon from '../shared/icons/Payment'
import ExchangeIcon from '../shared/icons/Exchange'
import VaultIcon from '../shared/icons/Vault'

const PackagesTable = ({ parcels, setParcels, api }) => {
  const [edit, setEdit] = useState(-1)
  const [currentRow, setCurrentRow] = useState({})

  const handleEditClick = index => {
    setEdit(prevIndex => {
      if (prevIndex !== -1) {
        handleCancel(prevIndex)
      }
      return index
    })
    setCurrentRow({ ...parcels[index] })
  }

  const handleSubmit = index => {
    api.updatePackage(parcels[index]).then(result => setEdit(-1))
  }

  const handleCancel = index => {
    setParcels(prevParcels => {
      const newParcels = prevParcels.filter((_, i) => i !== index)
      newParcels.splice(index, 0, currentRow)
      return newParcels
    })
    setEdit(-1)
  }

  const handleChange = index => event => {
    const rowColumn = event.target.name
    const { value } = event.target
    setParcels(prevParcels => {
      const newRow = prevParcels[index]
      newRow[rowColumn] = +value
      const newParcels = prevParcels.filter((_, i) => i !== index)
      newParcels.splice(index, 0, newRow)
      return newParcels
    })
  }

  const handleRefresh = (routeId, packageId, index) => {
    return api.updatePackageRow(routeId, packageId).then(res => {
      if (res.status === 200) {
        const { data } = res
        setParcels(prevParcels => {
          const newParcels = prevParcels.filter((_, i) => i !== index)
          newParcels.splice(index, 0, data)
          return newParcels
        })
      }
    })
  }

  const rowTotals = parcels.reduce(
    (acc, { card, cash, office, earned }) => ({
      card: acc.card + card,
      cash: acc.cash + cash,
      office: acc.office + office,
      earned: acc.earned + earned,
    }),
    { card: 0, cash: 0, office: 0, earned: 0 }
  )

  rowTotals.total = rowTotals.card + rowTotals.cash + rowTotals.office
  rowTotals.pay = rowTotals.earned - rowTotals.cash
  rowTotals.firm =
    rowTotals.card + rowTotals.cash + rowTotals.office - rowTotals.earned

  return (
    <table>
      <thead>
        <tr>
          <th>Владелец</th>
          <th>Отправитель</th>
          <th title="Время отправления">
            <TimeIcon />
          </th>
          <th title="Рейсы">
            <RouteIcon />
          </th>
          <th title="Карта">
            <CreditIcon />
          </th>
          <th title="Офис">
            <TicketIcon />
          </th>
          <th title="Наличными">
            <CashIcon />
          </th>
          <th title="Выручка">
            <TotalCashIcon />
          </th>
          <th title="Начислено">
            <PaymentIcon />
          </th>
          <th title="Прибыль">
            <VaultIcon />
          </th>
          <th title="Наличными">
            <CashIcon />
          </th>
          <th title="К выдаче">
            <ExchangeIcon />
          </th>
          <th></th>
        </tr>
      </thead>

      <tfoot>
        <tr>
          <td>Итого: {parcels.length}</td>
          <td>{rowTotals.card}</td>
          <td>{rowTotals.office}</td>
          <td>{rowTotals.cash}</td>
          <td>{rowTotals.card + rowTotals.cash + rowTotals.office}</td>
          <td>{rowTotals.earned}</td>
          <td>
            {rowTotals.card +
              rowTotals.cash +
              rowTotals.office -
              rowTotals.earned}
          </td>
          <td>{rowTotals.cash}</td>
          <td>{rowTotals.earned - rowTotals.cash}</td>
          <td></td>
        </tr>
      </tfoot>
      <tbody>
        {parcels.length < 1 ? (
          <tr>
            <td>
              Данные за выбранный период отсутствуют. Нажмите на кнопку
              «Подгрузить»
            </td>
          </tr>
        ) : (
          parcels.map((parcel, index) => {
            const total = parcel.card + parcel.cash + parcel.office
            const pay = parcel.earned - parcel.cash
            const firm =
              parcel.card + parcel.cash + parcel.office - parcel.earned
            return (
              <tr key={parcel.id}>
                <td>{parcel.ownerStr}</td>
                <td>{parcel.senderStr}</td>
                <td>
                  {`${getHours(new Date(parcel.dateTime))
                    .toString()
                    .padStart(2, '0')}:${getMinutes(new Date(parcel.dateTime))
                    .toString()
                    .padStart(2, '0')}`}
                </td>
                <td>
                  <GreenBlock>{parcel.direction}</GreenBlock>
                </td>
                <td>{parcel.card}</td>
                <td>{parcel.office}</td>
                <td>{parcel.cash}</td>
                <td>{total}</td>
                <td>
                  <EditableCell
                    disabled={edit !== index}
                    type="text"
                    name="earned"
                    value={parcel.earned}
                    onChange={handleChange(index)}
                  />
                </td>
                <td>{firm}</td>
                <td>{parcel.cash}</td>
                <td>{pay}</td>
                <td>
                  {edit === index ? (
                    <>
                      <SubmitButton handleClick={() => handleSubmit(index)} />
                      <CancelButton handleClick={() => handleCancel(index)} />
                    </>
                  ) : (
                    <>
                      <RefreshButton
                        handleClick={() =>
                          handleRefresh(parcel.routeId, parcel.packageId, index)
                        }
                      />
                      <EditButton handleClick={() => handleEditClick(index)} />
                    </>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </table>
  )
}

export default PackagesTable
