import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 448"
    width="24"
    height="24"
  >
    <path d="M192 54.664l-93.559 25.07L144.04 250.07a8.001 8.001 0 007.73 5.93h10.399l-31.727-118.633 7.743-2.054c21.328-5.731 33.964-27.672 28.214-48.993l-2.078-7.754L192 71.2zm0 0" />
    <path d="M149.895 148.168L178.777 256H192V87.77l-8.586 2.277c2.941 24.66-10.7 48.316-33.52 58.121zm0 0M0 240h64v208H0zm0 0" />
    <path d="M105.07 428.8l23.457 17.598a8.058 8.058 0 004.801 1.602H304c8.836 0 16-7.164 16-16s-7.164-16-16-16h-72v-16h104c8.836 0 16-7.164 16-16s-7.164-16-16-16H232v-16h136c8.836 0 16-7.164 16-16s-7.164-16-16-16H232v-16h104c8.836 0 16-7.164 16-16s-7.164-16-16-16H151.8a23.996 23.996 0 01-23.198-17.793l-4.122-15.39-18.152 18.152A23.826 23.826 0 0189.375 264H80v160h10.664a24.068 24.068 0 0114.406 4.8zm0 0" />
    <path d="M331.55 448H448V0H208v256h16V64h8c22.082-.027 39.973-17.918 40-40v-8h112v8c.027 22.082 17.918 39.973 40 40h8v320h-8c-22.082.027-39.973 17.918-40 40v8h-48a31.665 31.665 0 01-4.45 16zm0 0" />
    <path d="M336 416h32.566c3.586-24.559 22.875-43.848 47.434-47.434V79.434C391.441 75.848 372.152 56.559 368.566 32h-81.132C283.848 56.559 264.559 75.848 240 79.434V256h42.063c-17.684-25.172-11.782-59.898 13.23-77.813 25.012-17.91 59.789-12.32 77.926 12.528 18.14 24.847 12.867 59.676-11.82 78.039A31.197 31.197 0 01363.55 304H368c17.672 0 32 14.328 32 32s-14.328 32-32 32h-4.45a31.665 31.665 0 014.45 16c0 17.672-14.328 32-32 32zm0-280h-16V88h16zm0 0" />
    <path d="M328 184c-17.242-.031-32.555 11.008-37.973 27.375-5.418 16.367.285 34.363 14.141 44.625H336a31.869 31.869 0 0112.152 2.398c15.582-9.09 23.13-27.503 18.414-44.914C361.848 196.07 346.04 183.984 328 184zm0 0" />
  </svg>
)
