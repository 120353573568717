import React, { useState } from 'react'
import styled from 'styled-components'

const SortIcon = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  border: 0.5em solid currentColor;
  border-bottom-width: 0;
  border-left-width: 0.25em;
  border-right-width: 0.25em;
  border-left-color: transparent;
  border-right-color: transparent;
`

const SortingHeader = styled.th`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > ${SortIcon} {
      opacity: ${props => (props.isSorted ? '' : '0')};
      transform: ${props => (props.isInverted ? 'scaleY(-1)' : '')};
    }
  }
`

const RatingTable = ({ ratings }) => {
  const column = {
    0: 'carSchema',
    1: 'tripsCount',
    2: 'passengersCount',
    3: 'totalWeight',
    4: 'avg',
  }

  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(column[4])

  const handleSortClick = index => () => {
    if (index === sortedColumn) {
      setSortDirection(!sortDirection)
    } else {
      setSortDirection(true)
      setSortedColumn(index)
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th>№</th>
          <th>Водитель</th>
          <SortingHeader
            onClick={handleSortClick(column[0])}
            isSorted={sortedColumn === column[0]}
            isInverted={!sortDirection}
          >
            Схема <SortIcon />
          </SortingHeader>
          <SortingHeader
            onClick={handleSortClick(column[1])}
            isSorted={sortedColumn === column[1]}
            isInverted={!sortDirection}
          >
            Рейсы <SortIcon />
          </SortingHeader>
          <SortingHeader
            onClick={handleSortClick(column[2])}
            isSorted={sortedColumn === column[2]}
            isInverted={!sortDirection}
          >
            Пассажиры <SortIcon />
          </SortingHeader>
          <SortingHeader
            onClick={handleSortClick(column[3])}
            isSorted={sortedColumn === column[3]}
            isInverted={!sortDirection}
          >
            Кол-во отзывов <SortIcon />
          </SortingHeader>
          <SortingHeader
            onClick={handleSortClick(column[4])}
            isSorted={sortedColumn === column[4]}
            isInverted={!sortDirection}
          >
            Средний балл <SortIcon />
          </SortingHeader>
        </tr>
      </thead>
      <tbody>
        {ratings === undefined || ratings.length < 1 ? (
          <tr>
            <td>Данные за выбранные период отсутствуют</td>
          </tr>
        ) : (
          [...ratings]
            .sort((a, b) =>
              sortDirection
                ? b[sortedColumn] - a[sortedColumn]
                : a[sortedColumn] - b[sortedColumn]
            )
            .map(
              (
                {
                  driverName,
                  carSchema,
                  avg,
                  tripsCount,
                  passengersCount,
                  totalWeight,
                },
                index
              ) => (
                <tr key={`driver-${index + 1}`}>
                  <td>{index + 1}</td>
                  <td>{driverName}</td>
                  <td>{carSchema}</td>
                  <td>{tripsCount}</td>
                  <td>{passengersCount}</td>
                  <td>{totalWeight}</td>
                  <td>{avg}</td>
                </tr>
              )
            )
        )}
      </tbody>
    </table>
  )
}

export default RatingTable
