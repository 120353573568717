import React from 'react'
import { endOfWeek } from 'date-fns'

import RangeContainer from '../../shared/RangeContainer'
import ChangeWeekButton from './WeekSelect/ChangeWeekButton'

const WeekSelect = ({ selectedWeekStart, setSelectedWeekStart }) => {
  const end = endOfWeek(selectedWeekStart, { weekStartsOn: 1 })

  const ddStart = String(selectedWeekStart.getDate()).padStart(2, '0')
  const mmStart = String(selectedWeekStart.getMonth() + 1).padStart(2, '0')
  const yyyyStart = selectedWeekStart.getFullYear()

  const ddEnd = String(end.getDate()).padStart(2, '0')
  const mmEnd = String(end.getMonth() + 1).padStart(2, '0')
  const yyyyEnd = end.getFullYear()

  const text = `${ddStart}.${mmStart}.${yyyyStart} - ${ddEnd}.${mmEnd}.${yyyyEnd}`

  return (
    <RangeContainer>
      <ChangeWeekButton
        left={true}
        description="Предыдущая неделя"
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
      />
      <span>{text}</span>
      <ChangeWeekButton
        left={false}
        description="Следующая неделя"
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
      />
    </RangeContainer>
  )
}

export default WeekSelect
