import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 875 875"
    width="24"
    height="24"
  >
    <path d="M454.172 370.855c-30.024-33.476-28.078-84.726 4.383-115.847 32.468-31.102 83.77-30.86 115.933.57l143.121 143.14a47.57 47.57 0 001.141-8.093V146.594l-.148-.36h.148V46.875C718.75 20.992 697.758 0 671.875 0h-625C20.992 0 0 20.992 0 46.875v343.75C0 416.508 20.992 437.5 46.875 437.5h468.39zM625 46.875h31.25V93.75H625zm-62.5 0h31.25V93.75H562.5zm-62.5 0h31.25V93.75H500zm-62.5 0h31.25V93.75H437.5zm-406.25 100v-75c0-22.438 18.188-40.625 40.625-40.625h75c22.438 0 40.625 18.188 40.625 40.625v75c0 22.438-18.188 40.625-40.625 40.625h-75c-22.438 0-40.625-18.188-40.625-40.625zm78.125 212.5h-62.5v-31.25h62.5zm0-93.75h-62.5v-31.25h62.5zm93.75 0h-62.5v-31.25h62.5zm93.75 0h-62.5v-31.25h62.5zm93.75 93.75h-62.5v-31.25h62.5zm0-93.75h-62.5v-31.25h62.5zm0 0M72.625 468.75l16.484 42.777 111.063-42.777zm0 0" />
    <path d="M146.875 156.25c5.18 0 9.375-4.2 9.375-9.375V125H125V93.75h31.25V71.875a9.375 9.375 0 00-9.375-9.375h-75c-5.176 0-9.375 4.2-9.375 9.375V93.75h31.25V125H62.5v21.875c0 5.176 4.2 9.375 9.375 9.375zm0 0M796.531 386.688a46.51 46.51 0 00.89-35.93L750 227.727v162.898a78.145 78.145 0 01-6.594 31.25l27.106-10.45a46.612 46.612 0 0026.02-24.738zm0 0" />
    <path d="M824.313 252.969l-50.625-50.723 52.875 137.328c15.546 40.285-4.516 85.535-44.82 101.051l-16.024 6.25 57.816 57.813-22.094 22.093-248.976-249.093c-20.047-19.973-52.371-20.297-72.82-.715-20.442 19.566-21.516 51.886-2.422 72.773l128.125 139.7c4.582 5 5.418 12.41 2.043 18.308-35.68 62.344-34.157 139.273 3.957 200.168l6.777 10.828H875V374.863a173.058 173.058 0 00-50.688-121.894zm0 0M671.875 750H625v125h250V750zm15.625 62.5h-31.25v-31.25h31.25zm0 0" />
    <path d="M286.828 468.75v.094l-186.484 71.781 26.36 68.48c9.308 24.168 36.437 36.207 60.593 26.891l376.906-145.168-20.297-22.078zm0 0" />
  </svg>
)
