import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 448"
    width="24"
    height="24"
  >
    <path d="M209.762 402.082c-14.473-.016-27.14-9.715-30.93-23.684-.129-.484-.129-.964-.23-1.453L90.48 329.328a15.685 15.685 0 00-7.84-2.078c-1.027 0-2.054.094-3.066.285a15.03 15.03 0 00-4 1.297 15.705 15.705 0 00-6.87 6.344 16.24 16.24 0 00-2.145 8 15.904 15.904 0 007.953 13.886l145.847 87.274A24.07 24.07 0 00233.105 448h112.72a24.084 24.084 0 008.8-1.68L384 434.72v-86.184l-28.602-17.125A23.989 23.989 0 00343.07 328h-30.664c-4.883 0-9.746.64-14.46 1.902l-92.344 24.707a16.172 16.172 0 00-11.786 14.11v2.617c.055 1 .207 1.992.465 2.96a16.002 16.002 0 0015.48 11.786c.973-.082 5.68-1.043 9.329-1.883l82.847-22.168 4.125 15.457-83.117 22.239a78.242 78.242 0 01-13.183 2.355zm0 0M400 320h48v128h-48zm0 0M0 0h48v144H0zm0 0M91.297 125.887A72.144 72.144 0 00128 137.855V99.2a58.17 58.17 0 01-16.352-9.183l-4.687-3.801 10.078-12.43 4.64 3.758c15.852 12.672 38.454 12.336 53.923-.797l5.597-4.8 47.2 47.199c6.28 6.25 16.437 6.23 22.69-.047 6.25-6.282 6.231-16.438-.05-22.688l-56.453-56.515 11.309-11.31L225.312 48h66.016l-31.601-31.602A55.622 55.622 0 00220.129 0H119.55c-3.723 0-7.395.871-10.727 2.535l-17.937 8.969a40.162 40.162 0 01-19.344 4.191L64 15.425V120h18.398zm0 0" />
    <path d="M144 102.55V240h304V64H241.312l16 16H392v8c0 17.672 14.328 32 32 32h8v64h-8c-17.672 0-32 14.328-32 32v8H200v-8c0-17.672-14.328-32-32-32h-8v-64h8a31.925 31.925 0 0025.688-13l-13.649-13.648A58.183 58.183 0 01144 102.55zm0 0" />
    <path d="M239.777 139.754a32 32 0 01-22.609-9.274l-12.07-12.082A47.756 47.756 0 01176 135.336v33.328c20.129 3.441 35.895 19.207 39.336 39.336h161.328c3.441-20.129 19.207-35.895 39.336-39.336v-33.328c-20.129-3.441-35.895-19.207-39.336-39.336H269.535a31.456 31.456 0 01-2.62 28.719c14.67-15.54 38.972-16.797 55.17-2.852 16.204 13.945 18.58 38.16 5.395 54.985-13.18 16.828-37.261 20.316-54.68 7.921-17.413-12.39-22.007-36.285-10.433-54.253a32.212 32.212 0 01-22.59 9.234zm0 0" />
    <path d="M320 152c0 13.254-10.746 24-24 24s-24-10.746-24-24 10.746-24 24-24 24 10.746 24 24zm0 0" />
  </svg>
)
