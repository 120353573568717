import styled from 'styled-components'

const TableRow = styled.tr`
  &&& > td {
    background: ${props =>
      props.score < 3
        ? 'var(--pink)'
        : props.score < 5
        ? 'var(--soft-yellow)'
        : 'var(--ghost-green)'};
  }
`

export default TableRow
