import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import api from '../../../../services/api'
import DashboardIcon from '../../icons/Dashboard'
import RidesIcon from '../../icons/RidesIcon'
import SmsIcon from '../../icons/SmsIcon'
import FinancesIcon from '../../icons/FinancesIcon'
import LogoutIcon from '../../icons/LogoutIcon'
import StarIcon from '../../icons/Star'
import { textOverflow } from '../../../utils/styles'

const Nav = styled.nav`
  display: flex;
  user-select: none;
`

const List = styled.ul`
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 1em 1em 2em;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: calc(100% - 2rem);
    height: 1px;
    background: var(--dark-blue);
    transform: translateX(-50%);
  }

  li {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    &:last-child {
      align-self: flex-start;
      margin-top: auto;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 0.75em;
    font-weight: 500;
    line-height: 100%;
    color: var(--white);
    text-decoration: none;
    border-radius: 0.5em;
    outline: 0;
    transition: background 0.2s;

    &:hover:not([aria-current]) {
      background: var(--hover-blue);
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--light-blue);
    }

    &[aria-current] {
      background: var(--dark-blue);
    }

    svg {
      flex-shrink: 0;
      fill: var(--white);
    }

    span {
      ${textOverflow}
      margin-left: 0.5em;
    }
  }
`

const Navigation = ({ roleId }) => {
  const isAdmin = [1, 2].includes(roleId)
  return (
    <Nav>
      <List>
        <li>
          <Link to="/app">
            <SmsIcon />
            <span>SMS</span>
          </Link>
        </li>
        {isAdmin && (
          <li>
            <Link to="/app/rides">
              <RidesIcon />
              <span>Поездки</span>
            </Link>
          </li>
        )}
        {isAdmin && (
          <li>
            <Link to="/app/main">
              <DashboardIcon />
              <span>Диспетчеры</span>
            </Link>
          </li>
        )}
        {isAdmin && (
          <li>
            <Link to="/app/rating">
              <StarIcon />
              <span>Оценка качества</span>
            </Link>
          </li>
        )}
        {isAdmin && (
          <li>
            <Link to="/app/finances">
              <FinancesIcon />
              <span>Финансы</span>
            </Link>
          </li>
        )}
        <li>
          <a href="/" onClick={() => api.logout()}>
            <LogoutIcon />
            <span>Выход</span>
          </a>
        </li>
      </List>
    </Nav>
  )
}

export default Navigation
