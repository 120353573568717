import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import '../layout.css'
import api from '../../services/api'
import Fonts from '../shared/Fonts'
import SideMenu from './Layout/SideMenu'
import { roles } from '../utils/constants'

const Container = styled.div`
  display: flex;
  min-height: 100vh;

  body {
    min-width: 1200px;
  }

  main {
    flex-grow: 1;
    min-width: 1178px;
  }
`

export const UserContext = React.createContext({})

const Layout = ({ children }) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    const capitalize = str => str[0].toUpperCase() + str.slice(1).toLowerCase()
    api.getUser().then(({ data }) => {
      const { name, surname, role } = data
      const initials =
        name && surname && `${name[0]}${surname[0]}`.toUpperCase()
      const fullName =
        name && surname && `${capitalize(name)} ${capitalize(surname)}`
      const jobTitle = role && roles[role.user_role_id]
      const roleId = role && role.user_role_id
      setUser({ initials, fullName, jobTitle, roleId })
    })
  }, [])
  return (
    <UserContext.Provider value={user}>
      <Container>
        <Fonts />
        <SideMenu />
        <main>{children}</main>
      </Container>
    </UserContext.Provider>
  )
}

export default Layout
