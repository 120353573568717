import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="24"
    height="24"
  >
    <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 467c-116.346 0-211-94.654-211-211S139.654 45 256 45s211 94.654 211 211-94.654 211-211 211z" />
    <path d="M256 75c-99.804 0-181 81.196-181 181s81.196 181 181 181 181-81.196 181-181S355.804 75 256 75zm15 187.213l-100.56 100.56-21.213-21.213L241 249.787V120h30z" />
  </svg>
)
