import React from 'react'

import Loader from '../shared/Loader'

const Table = ({ logs, loading }) => {
  let totalCount = 0
  logs.forEach(item => (totalCount += item.count))

  return logs === undefined || loading ? (
    <Loader />
  ) : (
    <table>
      <thead>
        <tr>
          <th>№</th>
          <th>Диспетчер</th>
          <th>Пассажиры + Посылки</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>Итого:</td>
          <td>{totalCount}</td>
        </tr>
      </tfoot>
      <tbody>
        {logs.length < 1 ? (
          <tr>
            <td>Данные для выбранного периода отсутствуют</td>
          </tr>
        ) : (
          [...logs]
            .sort((a, b) => b.count - a.count)
            .map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.login}</td>
                <td>{item.count}</td>
              </tr>
            ))
        )}
      </tbody>
    </table>
  )
}

export default Table
