import React, { useState } from 'react'
import styled from 'styled-components'

import { routeLink, cities } from '../utils/constants'
import UnstyledLink from '../shared/UnstyledLink'
import EditableCell from '../shared/EditableCell'
import GreenBlock from '../shared/GreenBlock'
import Tooltip, { NoWrappingText } from '../shared/Tooltip'
import EditButton from './RidesTable/EditButton'
import CancelButton from './RidesTable/CancelButton'
import SubmitButton from './RidesTable/SubmitButton'
import RefreshButton from '../shared/RefreshButton'
import RouteIcon from '../shared/icons/Route'
import PassengersIcon from '../shared/icons/Passengers'
import CreditIcon from '../shared/icons/Credit'
import CashIcon from '../shared/icons/Cash'
import TicketIcon from '../shared/icons/Ticket'
import TotalCashIcon from '../shared/icons/TotalCash'
import PaymentIcon from '../shared/icons/Payment'
import ExchangeIcon from '../shared/icons/Exchange'
import VaultIcon from '../shared/icons/Vault'

const RouteBlock = styled(GreenBlock)`
  background: ${props => (props.isStandard ? '' : 'var(--light-yellow)')};
`

const TotalBlock = styled(GreenBlock)`
  background: ${props => (props.isPartPayment ? 'var(--light-red)' : '0')};
`

const RidesTable = ({ finances, setFinances, api }) => {
  const [edit, setEdit] = useState(-1)
  const [currentRow, setCurrentRow] = useState({})

  const handleEditClick = index => {
    setEdit(prevIndex => {
      if (prevIndex !== -1) {
        handleCancel(prevIndex)
      }
      return index
    })
    setCurrentRow({ ...finances[index] })
  }

  const handleSubmit = index => {
    api.updateFinances(finances[index]).then(result => setEdit(-1))
  }

  const handleCancel = index => {
    setFinances(prevFinances => {
      const newFinances = prevFinances.filter((_, i) => i !== index)
      newFinances.splice(index, 0, currentRow)
      return newFinances
    })
    setEdit(-1)
  }

  const handleChange = index => event => {
    const rowColumn = event.target.name
    const { value } = event.target
    setFinances(prevFinances => {
      const newRow = { ...prevFinances[index] }
      newRow[rowColumn] = +value
      const newFinances = prevFinances.filter((_, i) => i !== index)
      newFinances.splice(index, 0, newRow)
      return newFinances
    })
  }
  const handleRefresh = (id, index) => {
    return api.updateFinanceRow(id).then(res => {
      if (res.status === 200) {
        const { data } = res
        setFinances(prevFinances => {
          const newFinances = prevFinances.filter((_, i) => i !== index)
          newFinances.splice(index, 0, data)
          return newFinances
        })
      }
    })
  }

  const rowTotals = finances.reduce(
    (acc, { passengersTotal, card, cash, office, correction, earned }) => ({
      passengersTotal: acc.passengersTotal + passengersTotal,
      card: acc.card + card,
      cash: acc.cash + cash,
      office: acc.office + office,
      correction: acc.correction + correction,
      earned: acc.earned + earned,
    }),
    {
      passengersTotal: 0,
      card: 0,
      cash: 0,
      office: 0,
      correction: 0,
      earned: 0,
    }
  )

  rowTotals.total =
    rowTotals.card + rowTotals.cash + rowTotals.office + rowTotals.correction
  rowTotals.pay = rowTotals.earned - rowTotals.cash
  rowTotals.firm =
    rowTotals.card +
    rowTotals.cash +
    rowTotals.office +
    rowTotals.correction -
    rowTotals.earned

  return (
    <table>
      <thead>
        <tr>
          <th>Номер</th>
          <th>Владелец</th>
          <th title="Рейсы">
            <RouteIcon />
          </th>
          <th title="Кол-во пассажиров">
            <PassengersIcon />
          </th>
          <th title="Карта">
            <CreditIcon />
          </th>
          <th title="Офис">
            <TicketIcon />
          </th>
          <th title="Наличными">
            <CashIcon />
          </th>
          <th title="Выручка">
            <TotalCashIcon />
          </th>
          <th title="Начислено">
            <PaymentIcon />
          </th>
          <th title="Прибыль">
            <VaultIcon />
          </th>
          <th title="Наличными">
            <CashIcon />
          </th>
          <th title="К выдаче">
            <ExchangeIcon />
          </th>
          <th></th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>Итого:</td>
          <td>{rowTotals.passengersTotal}</td>
          <td>{rowTotals.card}</td>
          <td>{rowTotals.office}</td>
          <td>{rowTotals.cash}</td>
          <td>{rowTotals.card + rowTotals.cash + rowTotals.office}</td>
          <td>{rowTotals.earned}</td>
          <td>
            {rowTotals.card +
              rowTotals.cash +
              rowTotals.office -
              rowTotals.earned}
          </td>
          <td>{rowTotals.cash}</td>
          <td>{rowTotals.earned - rowTotals.cash}</td>
          <td></td>
        </tr>
      </tfoot>
      <tbody>
        {finances.length < 1 ? (
          <tr>
            <td>
              Данные за выбранный период отсутствуют. Нажмите на кнопку
              «Подгрузить»
            </td>
          </tr>
        ) : (
          finances.map((ride, index) => {
            const total = ride.card + ride.cash + ride.office
            const pay = ride.earned - ride.cash
            const firm = ride.card + ride.cash + ride.office - ride.earned
            return (
              <tr key={ride.id}>
                <td>{ride.carTitle}</td>
                <td>
                  {ride.carOwner}
                  <Tooltip>
                    <strong>Водитель:</strong>{' '}
                    <NoWrappingText>{ride.carDriver}</NoWrappingText>
                  </Tooltip>
                </td>
                <td>
                  <UnstyledLink
                    target="_blank"
                    rel="noopener nofollower"
                    href={`${routeLink}${ride.firstRouteId}`}
                  >
                    <RouteBlock isStandard={ride.isStandard}>
                      {cities[ride.from]} > {cities[ride.to]}
                    </RouteBlock>
                  </UnstyledLink>
                  {!ride.isSingleRoute ? (
                    <UnstyledLink
                      target="_blank"
                      rel="noopener nofollower"
                      href={`${routeLink}${ride.secondRouteId}`}
                    >
                      <RouteBlock isStandard={ride.isStandard}>
                        {cities[ride.to]} > {cities[ride.from]}
                      </RouteBlock>
                    </UnstyledLink>
                  ) : (
                    ''
                  )}
                </td>
                <td>{ride.passengersTotal}</td>
                <td>
                  <EditableCell
                    disabled={edit !== index}
                    name="card"
                    type="text"
                    value={ride.card}
                    onChange={handleChange(index)}
                  />
                </td>
                <td>
                  <EditableCell
                    disabled={edit !== index}
                    name="office"
                    type="text"
                    value={ride.office}
                    onChange={handleChange(index)}
                  />
                </td>
                <td>
                  <EditableCell
                    disabled={edit !== index}
                    name="cash"
                    type="text"
                    value={ride.cash}
                    onChange={handleChange(index)}
                  />
                </td>
                <td>
                  <TotalBlock isPartPayment={ride.isPartPayment}>
                    {total}
                  </TotalBlock>
                </td>
                <td>
                  <EditableCell
                    disabled={edit !== index}
                    name="earned"
                    type="text"
                    value={ride.earned}
                    onChange={handleChange(index)}
                  />
                </td>
                <td>{firm}</td>
                <td>{ride.cash}</td>
                <td>{pay}</td>
                <td>
                  {edit === index ? (
                    <>
                      <SubmitButton handleClick={() => handleSubmit(index)} />
                      <CancelButton handleClick={() => handleCancel(index)} />
                    </>
                  ) : (
                    <>
                      <RefreshButton
                        handleClick={() => handleRefresh(ride.id, index)}
                      />
                      <EditButton handleClick={() => handleEditClick(index)} />
                    </>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </table>
  )
}

export default RidesTable
