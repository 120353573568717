import React from 'react'
import styled, { keyframes } from 'styled-components'

import Spinner from './icons/Spinner'

const spin = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 2em 1em;

  svg {
    fill: currentColor;
    animation: ${spin} 1s steps(12) infinite;
  }
`

const Loader = () => (
  <Container>
    <Spinner />
  </Container>
)

export default Loader
