import React, { useContext } from 'react'
import styled from 'styled-components'

import UserInfo from './SideMenu/UserInfo'
import Navigation from './SideMenu/Navigation'
import { UserContext } from '../Layout'

const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 260px;
  max-width: 260px;
  height: 100vh;
  overflow: hidden;
  background: var(--blue);
  transition: max-width 0.2s;
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
`

const Container = styled.header`
  position: relative;
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  width: 72px;
  color: var(--white);
  border-right: 1px solid var(--dark-blue);

  &:not(:hover) {
    ${Wrapper} {
      width: 72px;
    }

    ${Grid} {
      max-width: 72px;
    }

    ul {
      width: 72px;
      align-items: flex-start;

      span {
        display: none;
      }
    }
  }
`

const SideMenu = () => {
  const user = useContext(UserContext)
  const { roleId } = user
  return (
    <Container>
      <Wrapper>
        <Grid>
          <UserInfo {...user} />
          <Navigation roleId={roleId} />
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default SideMenu
