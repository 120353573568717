import React from 'react'
import styled from 'styled-components'
import ReactExport from 'react-export-excel'
import { format } from 'date-fns'

import { cities } from '../utils/constants'
import Icon from '../shared/icons/Excel'

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
  color: var(--white);
  font-weight: 500;
  background: var(--green);
  border: 1px solid var(--dark-green);
  border-radius: 0.25em;
  cursor: pointer;
  transition: background 0.2s;

  &:hover:not(:active) {
    background: var(--light-green);
  }

  svg {
    fill: currentColor;
    margin-left: 0.5em;
  }
`

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const Export = ({ financesExcel, parcelsExcel }) => {
  return (
    <ExcelFile
      element={
        <Button>
          Экспорт <Icon />
        </Button>
      }
    >
      <ExcelSheet data={financesExcel} name={`Поездки `}>
        <ExcelColumn
          label="Тип"
          value={({ carSchema }) => (carSchema < 5 ? 'Легковая' : 'Минивен')}
        />
        <ExcelColumn
          label="Дата"
          value={({ startRouteDate }) =>
            `${format(new Date(startRouteDate), 'dd.MM.yyyy')}`
          }
        />
        <ExcelColumn label="Собственник" value="carOwner" />
        <ExcelColumn label="Пассажиров" value="passengersTotal" />
        <ExcelColumn label="Карта" value="card" />
        <ExcelColumn label="Офис" value="office" />
        <ExcelColumn label="Наличные" value="cash" />
        <ExcelColumn label="ФСС" value="fss" />
        <ExcelColumn label="Ч" value="passengersTotal" />
        <ExcelColumn
          label="Всего"
          value={({ card, cash, office }) => card + cash + office}
        />
        <ExcelColumn label="Начислено" value="earned" />
        <ExcelColumn
          label="Фирма"
          value={({ card, cash, office, earned }) =>
            card + cash + office - earned
          }
        />
        <ExcelColumn label="Наличные" value="cash" />
        <ExcelColumn
          label="Выдача"
          value={({ cash, earned }) => earned - cash}
        />
        <ExcelColumn label="Водитель" value="carDriver" />
        <ExcelColumn
          label="Направление"
          value={({ from, to }) => `${cities[from]}-${cities[to]}`}
        />
        <ExcelColumn
          label="Время"
          value={({ startRouteDate }) =>
            `${format(new Date(startRouteDate), 'kk:mm')}`
          }
        />
        <ExcelColumn label="Частичная оплата" value="isPartPayment" />
        <ExcelColumn label="П!!!" value="diffPassengers" />
      </ExcelSheet>
      <ExcelSheet data={parcelsExcel} name={`Посылки`}>
        <ExcelColumn label="Тип" value={col => 'Посылка'} />
        <ExcelColumn
          label="Дата"
          value={({ dateTime }) =>
            `${format(new Date(dateTime), 'dd.MM.yyyy')}`
          }
        />
        <ExcelColumn label="Собственник" value="ownerStr" />
        <ExcelColumn label="Пассажиров" value={col => '1'} />
        <ExcelColumn label="Карта" value="card" />
        <ExcelColumn label="Офис" value="office" />
        <ExcelColumn label="Наличные" value="cash" />
        <ExcelColumn label="ФСС" value={col => '0'} />
        <ExcelColumn label="Ч" value={col => '0'} />
        <ExcelColumn
          label="Всего"
          value={({ card, cash, office }) => card + cash + office}
        />
        <ExcelColumn label="Начислено" value="earned" />
        <ExcelColumn
          label="Фирма"
          value={({ card, cash, office, earned }) =>
            card + cash + office - earned
          }
        />
        <ExcelColumn label="Наличные" value="cash" />
        <ExcelColumn
          label="Выдача"
          value={({ cash, earned }) => earned - cash}
        />
        <ExcelColumn label="Водитель" value="carDriver" />
        <ExcelColumn label="Направление" value="direction" />
        <ExcelColumn
          label="Время"
          value={({ dateTime }) => `${format(new Date(dateTime), 'kk:mm')}`}
        />
      </ExcelSheet>
    </ExcelFile>
  )
}

export default Export
