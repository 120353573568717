import React from 'react'
import { Router } from '@reach/router'

import PrivateRoute from '../components/PrivateRoute'
import Layout from '../components/shared/Layout'
import Main from '../components/Main'
import Rides from '../components/Rides'
import Finances from '../components/Finances'
import Sms from '../components/Sms'
import Rating from '../components/Rating'

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app" component={Sms} />
      <PrivateRoute path="/app/rides" component={Rides} />
      <PrivateRoute path="/app/main" component={Main} />
      <PrivateRoute path="/app/rating" component={Rating} />
      <PrivateRoute path="/app/finances" component={Finances} />
    </Router>
  </Layout>
)

export default App
