import styled from 'styled-components'

const RouteLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  padding: 0.5em;
  color: var(--black);
  font-size: 14px;
  text-decoration: none;
  border: 1px dashed var(--gray);
  border-radius: 0.5em;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5em;
  }
`

export default RouteLink
