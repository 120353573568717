import styled from 'styled-components'

const TabButton = styled.button`
  padding: 0.25rem 0.5rem;
  color: var(--black);
  font-weight: 500;
  background: 0;
  border: 0;
  border-radius: 0.5rem;
  transition: background 0.2s, color 0.2s;

  &:not(:disabled) {
    color: var(--blue);
    cursor: pointer;

    &:hover {
      background: var(--lightest-gray);
    }
  }
`
export default TabButton
