import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 469.333 469.333"
    width="16"
    height="16"
  >
    <path d="M234.667 0c-106.027 0-192 85.973-192 192v149.333c0 35.307 28.693 64 64 64h64V234.667H85.333V192c0-82.453 66.88-149.333 149.333-149.333S384 109.547 384 192v42.667h-85.333v170.667H384v21.333H234.667v42.667h128c35.307 0 64-28.693 64-64V192c0-106.027-85.974-192-192-192z" />
  </svg>
)
