import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width="24"
    height="24"
  >
    <g clipPath="url(#clip0)">
      <path d="M26.611 26.751a30.155 30.155 0 019.613-6.48A30.074 30.074 0 0148 17.89c4.081 0 8.043.801 11.776 2.38a30.147 30.147 0 019.613 6.48c1.2 1.2 2.288 2.486 3.26 3.85v-3.244c0-10.402-8.463-18.865-18.864-18.865H30.384V.187L0 19.332l21.776 13.721a30.184 30.184 0 014.835-6.302zM96 76.948L74.224 63.226a30.204 30.204 0 01-4.835 6.303 30.14 30.14 0 01-9.613 6.48A30.062 30.062 0 0148 78.388c-4.081 0-8.043-.801-11.776-2.38a30.132 30.132 0 01-9.613-6.48 30.358 30.358 0 01-3.26-3.85v3.244c0 10.402 8.463 18.864 18.864 18.864h23.402v8.305L96 76.948zM51.948 39.965h-5.77v5.77h5.77a2.894 2.894 0 002.885-2.885 2.894 2.894 0 00-2.885-2.886z" />
      <path d="M47.62 24C34.089 24 23 35.089 23 48.62s11.089 24.62 24.62 24.62 24.62-11.089 24.62-24.62S61.151 24 47.62 24zm15.344 39.964c-4.12 4.121-9.57 6.39-15.344 6.39-5.774 0-11.223-2.269-15.344-6.39-4.121-4.12-6.39-9.57-6.39-15.344 0-5.774 2.269-11.223 6.39-15.344 4.12-4.121 9.57-6.39 15.344-6.39 5.774 0 11.223 2.269 15.344 6.39 4.121 4.12 6.39 9.57 6.39 15.344 0 5.774-2.269 11.223-6.39 15.344z" />
      <path d="M60.924 35.316c-3.576-3.576-8.3-5.546-13.304-5.546-5.003 0-9.728 1.97-13.304 5.546-3.576 3.576-5.546 8.3-5.546 13.304 0 5.003 1.97 9.728 5.546 13.304 3.576 3.576 8.3 5.546 13.304 5.546 5.003 0 9.728-1.97 13.304-5.546 3.576-3.576 5.546-8.3 5.546-13.304 0-5.003-1.97-9.728-5.546-13.304zM51.948 48.62h-5.77v2.885h7.212v2.885h-7.213v5.77h-2.885v-5.77h-2.885v-2.885h2.885V48.62h-2.885v-2.885h2.885v-8.656h8.656c3.173 0 5.77 2.597 5.77 5.77 0 3.174-2.597 5.771-5.77 5.771z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h96v96H0z" />
      </clipPath>
    </defs>
  </svg>
)
