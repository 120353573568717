import React from 'react'
import { addWeeks, isSameWeek } from 'date-fns'

import ArrowButton from '../../../shared/ArrowButton'
import ArrowIcon from '../../../shared/icons/ArrowIcon'

const ChangeWeekButton = ({
  description,
  left,
  selectedWeekStart,
  setSelectedWeekStart,
}) => {
  const changeWeek = left => {
    left
      ? setSelectedWeekStart(addWeeks(selectedWeekStart, -1))
      : setSelectedWeekStart(addWeeks(selectedWeekStart, 1))
  }

  return (
    <ArrowButton
      type="button"
      ariaLabel={description}
      onClick={() => changeWeek(left)}
      left={left}
      disabled={
        isSameWeek(new Date(), selectedWeekStart, { weekStartsOn: 1 }) && !left
      }
    >
      <ArrowIcon />
    </ArrowButton>
  )
}

export default ChangeWeekButton
