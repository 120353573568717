import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'

import GreenBlock from '../shared/GreenBlock'
import { routeLink } from '../utils/constants'
import UnstyledLink from '../shared/UnstyledLink'

const Table = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>ФИО</th>
          <th>Номер</th>
          <th>Время отправки сообщения</th>
          <th>Текст сообщения</th>
          <th>Маршрут</th>
          <th>Время отправления</th>
        </tr>
      </thead>
      <tbody>
        {data.length < 1 ? (
          <tr>
            <td>Данные за выбранный период отсутствуют</td>
          </tr>
        ) : (
          data.map(item => (
            <tr key={item.id}>
              <td>{item.fio}</td>
              <td>{item.phone}</td>
              <td>{format(new Date(item.sendTime), "dd.MM.yy', 'HH:mm")}</td>
              <td>
                <small>{item.text}</small>
              </td>
              <td>
                <UnstyledLink
                  href={`${routeLink}${item.routeId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GreenBlock>{item.direction}</GreenBlock>
                </UnstyledLink>
              </td>
              <td>
                {format(new Date(item.routeDateTime), "dd.MM.yy', 'HH:mm")}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

export default Table
