import React from 'react'
import styled from 'styled-components'
import { eachDayOfInterval, isToday } from 'date-fns'

import { centeredFlex } from '../utils/styles'
import GreenBlock from '../shared/GreenBlock'
import Tooltip from '../shared/Tooltip'
import { routeLink } from '../utils/constants'
import UnstyledLink from '../shared/UnstyledLink'

const StyledGreenBlock = styled(GreenBlock)`
  &:hover > ${Tooltip} {
    opacity: 1;
  }
`

const Rate = styled.span`
  ${centeredFlex}
  padding: 0.5em;
  width: 4em;
  height: 2em;
  color: ${props => (props.rate < 5 ? '#0c3271' : 'var(--white)')};
  font-weight: 700;
  background: ${props =>
    props.rate < 3
      ? '#cde1ff'
      : props.rate < 5
      ? '#96bfff'
      : props.rate < 7
      ? '#2566d2'
      : '#083e95'};
  border-radius: 0.25em;
`

const Table = ({ cars, start, end }) => {
  const numberToDay = {
    1: 'пн',
    2: 'вт',
    3: 'ср',
    4: 'чт',
    5: 'пт',
    6: 'сб',
    0: 'вс',
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Автомобиль</th>
          <th>Схема</th>
          {eachDayOfInterval({ start, end }).map(date => {
            const day = String(date.getDate()).padStart(2, '0')
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const dayOfWeek = date.getDay()
            const style = isToday(date)
              ? { color: 'var(--blue)', background: 'var(--bg-main)' }
              : {}

            return (
              <th
                style={style}
              >{`${day}.${month}, ${numberToDay[dayOfWeek]}`}</th>
            )
          })}
          <th>Итого</th>
        </tr>
      </thead>
      <tbody>
        {cars.length < 1 ? (
          <tr>
            <td>Данные отсутствуют</td>
          </tr>
        ) : (
          cars.map(car => (
            <tr key={car.number}>
              <td>
                {car.number}
                <Tooltip>{car.title}</Tooltip>
              </td>
              <td>{car.scheme}</td>
              {car.perDays.map((day, i) => {
                return (
                  <td key={i}>
                    {day === ''
                      ? ''
                      : day.map(({ direction, info, routeId }, j) => (
                          <UnstyledLink
                            href={`${routeLink}${routeId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StyledGreenBlock key={j}>
                              <Tooltip>{info}</Tooltip>
                              {`${direction.from} > ${direction.to}`}
                            </StyledGreenBlock>
                          </UnstyledLink>
                        ))}
                  </td>
                )
              })}
              <td>
                <Rate rate={+car.total}>{car.total}</Rate>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

export default Table
