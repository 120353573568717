import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  startOfToday,
  isSameWeek,
} from 'date-fns'

import SEO from '../shared/seo'
import api from '../../services/api'
import TableContainer, { FixedFooter } from '../shared/TableContainer'
import RidesTable from './RidesTable'
import PackagesTable from './PackagesTable'
import Header from './Header'
import Loader from '../shared/Loader'

const RidesGrid = css`
  grid-template-columns:
    minmax(100px, 120px) minmax(160px, 200px) minmax(90px, 120px)
    minmax(50px, 80px) repeat(8, minmax(80px, 1fr)) auto;

  ${FixedFooter}

  tbody {
    td:last-child {
      flex-direction: row;

      & > *:not(:last-child) {
        margin: 0;
        margin-right: 0.5em;
      }
    }
  }
`

const PackagesGrid = css`
  grid-template-columns: repeat(12, auto) minmax(90px, 100px);

  ${FixedFooter}

  tbody {
    td:last-child {
      flex-direction: row;

      & > *:not(:last-child) {
        margin: 0;
        margin-right: 0.5em;
      }
    }
  }

  tfoot {
    td:first-child {
      grid-column-end: 5;
    }
  }
`

const StyledContainer = styled(TableContainer)`
  ${props => (props.currentTab === 0 ? RidesGrid : PackagesGrid)}
`

const Finances = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const [selectedDay, setSelectedDay] = useState(startOfToday())
  const [isAnotherWeek, setIsAnotherWeek] = useState(true)
  const [finances, setFinances] = useState([])
  const [parcels, setParcels] = useState([])
  const [loading, setLoading] = useState(false)
  const [financesExcel, setFinancesExcel] = useState([])
  const [parcelsExcel, setParcelsExcel] = useState([])

  const handleDaySelect = day => {
    isSameWeek(selectedDay, day, { weekStartsOn: 1 })
      ? setIsAnotherWeek(false)
      : setIsAnotherWeek(true)
    setSelectedDay(day)
  }
  const getFinances = (start, end, setResult) => {
    api
      .getFinances(start, end)
      .then(res => {
        setLoading(false)
        const { finances } = res.data
        setResult(finances)
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const getPackages = (start, end, setResult) => {
    api
      .getPackages(start, end)
      .then(res => {
        const { packages } = res.data
        setResult(packages)
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const getDayData = () => {
    getFinances(startOfDay(selectedDay), endOfDay(selectedDay), setFinances)
    getPackages(startOfDay(selectedDay), endOfDay(selectedDay), setParcels)
  }

  const getWeekData = () => {
    const weekStart = startOfWeek(selectedDay, { weekStartsOn: 1 })
    const weekEnd =
      selectedDay < endOfWeek(selectedDay, { weekStartsOn: 1 })
        ? selectedDay
        : endOfWeek(selectedDay, { weekStartsOn: 1 })
    getFinances(startOfDay(weekStart), endOfDay(weekEnd), setFinancesExcel)
    getPackages(startOfDay(weekStart), endOfDay(weekEnd), setParcelsExcel)
  }

  const getData = () => {
    getDayData()
    if (isAnotherWeek) {
      getWeekData()
    }
  }

  const handleCalculateFinances = () => {
    api
      .calculateFinances(startOfDay(selectedDay), endOfDay(selectedDay))
      .then(() => {
        console.log('ок')
        getData()
      })
  }

  const toThisDay = () => {
    setSelectedDay(startOfToday(new Date(), { weekStartsOn: 1 }))
  }

  useEffect(() => {
    getData()
    setLoading(true)
  }, [selectedDay])

  return (
    <StyledContainer currentTab={currentTab}>
      <SEO title="Финансы" />
      <Header
        toThisDay={toThisDay}
        financesExcel={financesExcel}
        parcelsExcel={parcelsExcel}
        selectedDay={selectedDay}
        handleDaySelect={handleDaySelect}
        handleCalculateFinances={handleCalculateFinances}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {loading ? (
        <Loader />
      ) : currentTab === 0 ? (
        <RidesTable finances={finances} setFinances={setFinances} api={api} />
      ) : (
        <PackagesTable parcels={parcels} setParcels={setParcels} api={api} />
      )}
    </StyledContainer>
  )
}
export default Finances
