import { css } from 'styled-components'

export const textOverflow = css`
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const borderedBlock = css`
  padding: 1rem;
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
`

export const centeredFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
