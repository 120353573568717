import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    width="32"
    height="32"
  >
    <path
      fill="#165BCF"
      d="M17.882 1.93a1.882 1.882 0 11-3.763 0 1.882 1.882 0 013.763 0z"
    />
    <path
      fill="#4A8DFF"
      d="M16 28.282a1.882 1.882 0 10-.001 3.764A1.882 1.882 0 0016 28.282z"
    />
    <path
      fill="#ACCBFF"
      d="M23.06 1.938a1.883 1.883 0 100 3.766 1.883 1.883 0 000-3.766z"
    />
    <path
      fill="#367CF3"
      d="M8.943 26.39a1.882 1.882 0 10-.004 3.766 1.882 1.882 0 00.004-3.765z"
    />
    <path
      fill="#1D64DC"
      d="M8.94 1.938a1.882 1.882 0 10.003 3.766 1.882 1.882 0 00-.004-3.766z"
    />
    <path
      fill="#5292FF"
      d="M23.057 26.39a1.882 1.882 0 10.004 3.764 1.882 1.882 0 00-.004-3.763z"
    />
    <path
      fill="#2D71E4"
      d="M1.882 14.165a1.882 1.882 0 100 3.764 1.882 1.882 0 000-3.764z"
    />
    <path
      fill="#6FA4FF"
      d="M30.118 14.165a1.882 1.882 0 10-.001 3.763 1.882 1.882 0 000-3.763z"
    />
    <path
      fill="#2C70E3"
      d="M3.775 7.106a1.881 1.881 0 101.63 2.824 1.883 1.883 0 00-1.63-2.824z"
    />
    <path
      fill="#5C99FF"
      d="M28.228 21.224a1.881 1.881 0 10-.003 3.764 1.881 1.881 0 10.003-3.765z"
    />
    <path
      fill="#3076EC"
      d="M3.772 21.224a1.881 1.881 0 10.943 3.512 1.883 1.883 0 00-.943-3.512z"
    />
    <path
      fill="#93BBFF"
      d="M28.225 7.106a1.883 1.883 0 10.003 3.765 1.883 1.883 0 00-.003-3.765z"
    />
  </svg>
)
