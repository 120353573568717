import styled from 'styled-components'

const RedactorInfo = styled.small`
  display: flex;
  width: 100%;
  margin-top: 0.25rem;
  padding: 0 0.5rem;
  color: var(--gray);
`

export default RedactorInfo
