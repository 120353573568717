import React from 'react'
import styled from 'styled-components'
import DatePicker from 'react-date-picker'
import { startOfToday } from 'date-fns'

import ReturnButton from '../shared/ReturnButton'
import ContentHeader from '../shared/ContentHeader'
import TabsContainer from '../shared/TabsContainer'
import TabButton from '../shared/TabButton'
import Export from './Export'

const RidesHeader = ({
  toThisDay,
  currentTab,
  setCurrentTab,
  selectedDay,
  handleDaySelect,
  handleCalculateFinances,
  parcelsExcel,
  financesExcel,
}) => {
  const handleDate = e => handleDaySelect(e)

  const handleTabSelect = () => {
    setCurrentTab(previousTab => (previousTab === 0 ? 1 : 0))
  }

  return (
    <ContentHeader>
      <ReturnButton handler={toThisDay} />
      <DatePicker
        className="date-picker"
        locale="ru-RU"
        showLeadingZeros
        value={selectedDay}
        onChange={handleDate}
        maxDate={startOfToday()}
        clearIcon={null}
        calendarIcon={null}
        dayPlaceholder={'дд'}
        monthPlaceholder={'мм'}
        yearPlaceholder={'гггг'}
        required
      />
      <TabsContainer>
        <TabButton
          type="button"
          disabled={currentTab === 0}
          onClick={handleTabSelect}
        >
          Пассажиры
        </TabButton>
        <TabButton
          type="button"
          disabled={currentTab === 1}
          onClick={handleTabSelect}
        >
          Посылки
        </TabButton>
      </TabsContainer>
      <Export
        financesExcel={financesExcel}
        parcelsExcel={parcelsExcel}
        selectedDay={selectedDay}
      />
      <TabButton type="button" onClick={handleCalculateFinances}>
        Подгрузить
      </TabButton>
    </ContentHeader>
  )
}

export default RidesHeader
