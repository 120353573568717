import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { startOfDay, endOfDay, startOfToday } from 'date-fns'

import api from '../../services/api'
import SEO from '../shared/seo'
import TableContainer from '../shared/TableContainer'
import { cities } from '../utils/constants'
import Header from './Header'
import Table from './Table'

const StyledContainer = styled(TableContainer)`
  grid-template-columns: repeat(6, auto);
`

const Sms = () => {
  const [selectedDay, setSelectedDay] = useState(startOfToday())
  const [messages, setMessages] = useState([])
  const toThisDay = () => {
    setSelectedDay(startOfToday(new Date(), { weekStartsOn: 1 }))
  }

  useEffect(() => {
    api
      .getMessages(startOfDay(selectedDay), endOfDay(selectedDay))
      .then(({ data }) => {
        data.map(item => {
          item.direction = `${cities[item.from]} > ${cities[item.to]}`
          const words = item.text.split(' ')
          item.text = words.slice(0, words.indexOf('Ostavte')).join(' ')
        })
        setMessages(data)
      })
  }, [selectedDay])
  return (
    <StyledContainer>
      <SEO title="SMS" />
      <Header
        toThisDay={toThisDay}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
      <Table data={messages} />
    </StyledContainer>
  )
}

export default Sms
