import React from 'react'

const FinancesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 57"
    width="16"
    height="16"
  >
    <path d="M32.36 49.422H19.937V57H8.217v-7.578H.29V39.93h7.93v-2.97H.289V27.47h7.93V.125h22.11c4.27 0 8.02.768 11.25 2.305 3.228 1.536 5.728 3.737 7.5 6.601 1.796 2.839 2.694 6.081 2.694 9.727 0 5.625-1.914 10.065-5.742 13.32-3.802 3.255-9.101 4.883-15.898 4.883H19.938v2.969h12.421v9.492zM19.937 27.469h10.117c6.562 0 9.843-2.878 9.843-8.633 0-2.734-.833-4.935-2.5-6.602-1.666-1.692-3.97-2.552-6.914-2.578H19.937V27.47z" />
  </svg>
)

export default FinancesIcon
