import React from 'react'
import { addMonths, subMonths, getMonth, getYear } from 'date-fns'

import RangeContainer from './RangeContainer'
import ArrowButton from './ArrowButton'
import ArrowIcon from './icons/ArrowIcon'

const MonthSelect = ({ selectedMonth, setSelectedMonth, loading }) => {
  const monthName = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  }
  const setPrevMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1))
  }
  const setNextMonth = () => setSelectedMonth(addMonths(selectedMonth, 1))

  return (
    <RangeContainer>
      <ArrowButton left onClick={() => setPrevMonth()} disabled={loading}>
        <ArrowIcon />
      </ArrowButton>
      <span>{`${monthName[getMonth(selectedMonth)]} ${getYear(
        selectedMonth
      )}`}</span>
      <ArrowButton onClick={() => setNextMonth()} disabled={loading}>
        <ArrowIcon />
      </ArrowButton>
    </RangeContainer>
  )
}

export default MonthSelect
