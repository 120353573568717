import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { startOfWeek, eachDayOfInterval, endOfWeek, isSameDay } from 'date-fns'

import api from '../../services/api'
import SEO from '../shared/seo'
import TableContainer from '../shared/TableContainer'
import { cities, citiesName } from '../utils/constants'
import Loader from '../shared/Loader'
import Header from './Header'
import Table from './Table'

const StyledContainer = styled(TableContainer)`
  grid-template-columns: auto auto 1fr 1fr 1fr 1fr 1fr 1fr 1fr auto;
`

const Rides = () => {
  const currentWeekStart = startOfWeek(new Date(), {
    weekStartsOn: 1,
  })
  const [selectedWeekStart, setSelectedWeekStart] = useState(currentWeekStart)
  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState(false)
  const start = selectedWeekStart
  const end = endOfWeek(selectedWeekStart, { weekStartsOn: 1 })
  const [carSchemas, setCarSchemas] = useState([])
  const [hiddenSchemas, setHiddenSchemas] = useState([])

  const massage = cars => {
    const maxTrips = Math.max(
      ...cars.map(car => Math.ceil(car.carRoutes.length / 2))
    )
    const setSchema = new Set([...cars].map(car => car.scheme))
    const arraySchema = Array.from(setSchema)
      .sort()
      .reverse()
    setCarSchemas(arraySchema)

    return [...cars]
      .sort((b, a) => {
        if (a.scheme === b.scheme) {
          return a.carRoutes.length - b.carRoutes.length
        }
        return a.scheme - b.scheme
      })
      .map(({ car, carRoutes, scheme }) => {
        const row = {}
        row.title = car.title
        row.number = car.number
        row.scheme = scheme
        row.total = Math.ceil(carRoutes.length / 2)
        row.perDays = eachDayOfInterval({ start, end }).map((currentDay, i) => {
          const currentRoutes = carRoutes.filter(route =>
            isSameDay(new Date(route.fromTime), currentDay)
          )
          if (currentRoutes.length === 0) {
            return ''
          }
          return currentRoutes.map((route, j) => {
            const passengersCount = route.passengers.filter(
              passenger => passenger.state <= 3
            ).length
            const routeTime = new Date(route.fromTimeLocal)
            const infoRoute =
              `${citiesName[route.fromCityId]}-${citiesName[route.toCityId]},
                              Пассажиров: ${passengersCount}\nОтпр.:` +
              `0${routeTime.getUTCHours()}`.slice(-2) +
              `:` +
              `0${routeTime.getUTCMinutes()}`.slice(-2)
            return {
              direction: {
                from: cities[route.fromCityId] || route.fromCityId,
                to: cities[route.toCityId] || route.toCityId,
              },
              info: infoRoute,
              routeId: route.id,
            }
          })
        })
        return row
      })
  }

  useEffect(() => {
    setLoading(true)
    api.getTrips(start, end).then(res => {
      setLoading(false)
      const routes = res.data
      const cars = routes.reduce((acc, route) => {
        if (route.carId && !acc.includes(route.carId)) {
          acc.push(route.carId)
        }
        return acc
      }, [])
      const result = cars.map(carId => {
        const {
          car,
          carScheme: { seats },
        } = routes.find(route => route.carId === carId)
        const carRoutes = routes.filter(route => carId === route.carId)
        return { car, scheme: seats, carRoutes }
      })
      setCars(massage(result))
    })
  }, [selectedWeekStart])
  const filteredCars = cars.filter(car => !hiddenSchemas.includes(car.scheme))
  const toThisWeek = () =>
    setSelectedWeekStart(startOfWeek(new Date(), { weekStartsOn: 1 }))
  return (
    <StyledContainer>
      <SEO title="Поездки" />
      <Header
        carSchemas={carSchemas}
        hiddenSchemas={hiddenSchemas}
        setHiddenSchemas={setHiddenSchemas}
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
        toThisWeek={toThisWeek}
      />
      {loading ? (
        <Loader />
      ) : (
        <Table cars={filteredCars} start={start} end={end} />
      )}
    </StyledContainer>
  )
}

export default Rides
