import React from 'react'

const SmsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.4 0H1.6C0.72 0 0.00799999 0.72 0.00799999 1.6L0 16L3.2 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6C16 0.72 15.28 0 14.4 0ZM5.6 7.2H4V5.6H5.6V7.2ZM8.8 7.2H7.2V5.6H8.8V7.2ZM12 7.2H10.4V5.6H12V7.2Z" />
    </svg>
  )
}

export default SmsIcon
