import React from 'react'

import Button from '../../shared/StyledButton'
import Tooltip from '../../shared/Tooltip'
import PencilIcon from '../../shared/icons/Pencil'

const EditButton = ({ handleClick }) => {
  return (
    <Button type="button" onClick={handleClick}>
      <PencilIcon />
      <Tooltip>Редактировать</Tooltip>
    </Button>
  )
}

export default EditButton
