import React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="24"
    height="24"
  >
    <path d="M466.089 139.805c-25.928 25.928-67.966 25.928-93.895 0-25.929-25.928-25.928-67.967 0-93.895L326.284 0 0 326.284l45.911 45.911c25.928-25.928 67.967-25.928 93.895 0 25.928 25.928 25.928 67.967 0 93.895l45.91 45.91L512 185.716l-45.911-45.911zm-210.57 43.141l-21.298-21.298 21.213-21.213 21.298 21.298-21.213 21.213zm42.596 42.597l-21.299-21.298 21.213-21.213 21.299 21.298-21.213 21.213zm42.596 42.597l-21.298-21.299 21.213-21.213 21.298 21.299-21.213 21.213z" />
  </svg>
)
