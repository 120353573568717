import React from 'react'

import Checkbox from '../../shared/Checkbox'
import ListContainer, { Label } from '../../shared/ListContainer'

const Schema = ({ carSchemas, setHiddenSchemas, hiddenSchemas }) => {
  const handleSetHiddenSchemas = schema => {
    setHiddenSchemas(prevHiddenSchemas => {
      if (prevHiddenSchemas.includes(schema)) {
        return prevHiddenSchemas.filter(item => item !== schema)
      }
      return [...prevHiddenSchemas, schema]
    })
  }
  return (
    <ListContainer>
      <Label>Схема:</Label>
      <ul>
        {carSchemas &&
          carSchemas.map(schema => (
            <li key={schema}>
              <Checkbox
                checked={!hiddenSchemas.includes(schema)}
                value={schema}
                label={schema}
                handle={{ callback: handleSetHiddenSchemas, data: schema }}
              />
            </li>
          ))}
      </ul>
    </ListContainer>
  )
}

export default Schema
