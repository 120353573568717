import styled from 'styled-components'

export default styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0%;
  padding: calc(0.5em - 2px);
  color: var(--white);
  background: var(--blue);
  border: 0;
  border: 1px solid var(--dark-blue);
  border-radius: 0.25em;
  cursor: pointer;
  transition: background 0.2s;

  svg {
    fill: currentColor;
    pointer-events: none;
  }

  &:hover {
    background: var(--light-blue);
  }
`
