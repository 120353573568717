import React from 'react'
import { navigate } from 'gatsby'
import api from '../services/api'
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const isBrowser = typeof window !== 'undefined'
  if (!api.token && isBrowser) {
    navigate('/')
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
