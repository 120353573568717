import React from 'react'
import styled from 'styled-components'

import CheckIcon from '../../images/check-icon.svg'

const Label = styled.label`
  position: relative;
  line-height: 100%;
  cursor: pointer;

  input {
    appearance: none;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + span::before {
      border-color: var(--blue);
    }

    &:checked + span::after {
      background-color: var(--blue);
      background-image: url("${CheckIcon}");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 1.5em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 0.25em;
      transform: translateY(-50%);
    }

    &::before {
      border: 2px solid var(--gray);
    }
  }
`

const Checkbox = ({ value, label, required, handle, checked }) => {
  return (
    <Label>
      <input
        checked={checked}
        type="checkbox"
        value={value}
        required={required}
        onChange={() => handle.callback(handle.data)}
      />
      <span>{label}</span>
    </Label>
  )
}

export default Checkbox
