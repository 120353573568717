import React from 'react'

const RidesIcon = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.151 1.898A1.328 1.328 0 0012.89 1H3.11c-.587 0-1.075.373-1.262.898L0 7.222v7.111c0 .49.4.89.889.89h.889c.489 0 .889-.4.889-.89v-.889h10.666v.89c0 .488.4.888.89.888h.888c.489 0 .889-.4.889-.889v-7.11l-1.849-5.325zm-11.04 8.88a1.332 1.332 0 01-1.333-1.334c0-.737.595-1.333 1.333-1.333s1.333.596 1.333 1.333c0 .738-.595 1.334-1.333 1.334zm9.778 0a1.332 1.332 0 01-1.333-1.334c0-.737.595-1.333 1.333-1.333s1.333.596 1.333 1.333c0 .738-.595 1.334-1.333 1.334zM1.778 6.333l1.333-4h9.778l1.333 4H1.778z" />
    </svg>
  )
}

export default RidesIcon
