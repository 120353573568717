import React from 'react'
import DatePicker from 'react-date-picker'
import { startOfToday } from 'date-fns'

import ContentHeader from '../shared/ContentHeader'
import ReturnButton from '../shared/ReturnButton'
import TabsContainer from '../shared/TabsContainer'
import TabButton from '../shared/TabButton'
import MonthSelect from '../shared/MonthSelect'
import Checkbox from '../shared/Checkbox'

const Header = ({
  toToday,
  toCurrentMonth,
  selectedDay,
  setSelectedDay,
  currentTab,
  setCurrentTab,
  openedOnly,
  setOpenedOnly,
  selectedMonth,
  setSelectedMonth,
  loading,
}) => {
  const handleTabSelect = () => {
    setCurrentTab(previousTab => (previousTab === 0 ? 1 : 0))
  }

  return (
    <ContentHeader>
      <TabsContainer>
        <TabButton
          type="button"
          disabled={currentTab === 0}
          onClick={handleTabSelect}
        >
          Отзывы
        </TabButton>
        <TabButton
          type="button"
          disabled={currentTab === 1}
          onClick={handleTabSelect}
        >
          Рейтинг
        </TabButton>
      </TabsContainer>
      {currentTab === 0 ? (
        <>
          <ReturnButton handler={toToday} isDisabled={openedOnly} />
          <DatePicker
            className="date-picker"
            locale="ru-RU"
            showLeadingZeros
            value={selectedDay}
            onChange={setSelectedDay}
            maxDate={startOfToday()}
            clearIcon={null}
            calendarIcon={null}
            dayPlaceholder={'дд'}
            monthPlaceholder={'мм'}
            yearPlaceholder={'гггг'}
            disabled={openedOnly}
            required
          />
          <Checkbox
            checked={openedOnly}
            handle={{ callback: () => setOpenedOnly(!openedOnly), data: '' }}
            label="Все открытые"
          />
        </>
      ) : (
        <>
          <ReturnButton handler={toCurrentMonth} />
          <MonthSelect
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            loading={loading}
          />
        </>
      )}
    </ContentHeader>
  )
}

export default Header
